import React from "react";
import PropTypes from "prop-types";
import Banner from "../../Banner";

function SavingsCall({ onClick }) {
    return (
        <Banner
            title="Identify Opportunities to Save"
            body="We've helped hundreds of businesses realize significant savings. From reducing health
            insurance premiums to securing discounts for equipment,
            we'll analyze your business's specific expenses and develop
            a bespoke playbook to drive savings and increase your
            bottom line."
            buttonText="Schedule Savings Call"
            buttonOnClick={onClick}
        />
    );
}

SavingsCall.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default SavingsCall;
