import React from "react";
import PageTitle from "../../PageTitle";
import ValuationRange from "./ValuationRange";
import FreeValuation from "./FreeValuation";
import useGetValuationQuery from "../../../hooks/queriesAndMutations/useGetValuationQuery";
import styles from "./styles.module.css";

function ValuationEstimate() {
    const { data: valuationData } = useGetValuationQuery();
    return valuationData ? (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Valuation Range</PageTitle>
            </div>
            <div className={styles.subheading}>
                This is our best estimate of the Valuation Range of your
                practice based on your financial data.
            </div>
            <div className={styles.cardsContainer}>
                <div className={styles.valuationRangeContainer}>
                    <ValuationRange />
                </div>
                <FreeValuation />
            </div>
        </div>
    ) : null;
}

export default ValuationEstimate;
