import React from "react";
import PageTitle from "../../PageTitle";
import MercuryDashboardSvg from "../../../assets/MercuryDashboard.svg";
import Logos from "./Logos";
import SignupAndScheduleDemo from "../SignupAndScheduleDemo";
import styles from "./styles.module.css";

function HeaderSection() {
    return (
        <div className={styles.container}>
            <div className={styles.leftSectionContainer}>
                <div className={styles.logosContainer}>
                    <Logos />
                </div>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>
                        Bringing Best-In-Class Banking To Healthcare
                    </PageTitle>
                </div>
                <div className={styles.body}>
                    Flychain and Mercury
                    <sup className={styles.superscript}>1</sup> have partnered
                    up to bring a delightful banking experience to the
                    healthcare space. Mercury enables over 200K businesses of
                    all sizes to operate at their highest level. Join a growing
                    number of healthcare providers who trust Mercury to scale
                    their practice.
                </div>
                <SignupAndScheduleDemo />
            </div>
            <div className={styles.spacer} />
            <div className={styles.rightSectionContainer}>
                <img src={MercuryDashboardSvg} alt="MercuryDashboard" />
            </div>
        </div>
    );
}

export default HeaderSection;
