import { useParams } from "react-router-dom";
import { useLazyDownloadExpensesByCategoryAsyncQuery as useAdminLazyDownloadExpensesByCategoryAsyncQuery } from "../../../redux/slices/adminApi";
import { useLazyDownloadExpensesByCategoryAsyncQuery as useProviderLazyDownloadExpensesByCategoryAsyncQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useLazyDownloadExpensesByCategoryAsyncQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const [adminTrigger, adminResult] =
        useAdminLazyDownloadExpensesByCategoryAsyncQuery();

    const [providerTrigger, providerResult] =
        useProviderLazyDownloadExpensesByCategoryAsyncQuery();

    const { providerId, businessEntityId } = useParams();

    const preloadParams = isAdminDashboard
        ? { providerId, businessEntityId }
        : { businessEntityId };

    const triggerWithParams = (params) => {
        const finalParams = { ...preloadParams, ...params };

        if (isAdminDashboard) {
            return adminTrigger(finalParams);
        }

        return providerTrigger(finalParams);
    };

    return [triggerWithParams, isAdminDashboard ? adminResult : providerResult];
};

export default useLazyDownloadExpensesByCategoryAsyncQuery;
