import React from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import ExportReportButton from "../../ExportReportButton";
import {
    updateDateRange,
    serializeDateRange,
    selectDateRange,
} from "../../../../redux/slices/dateRange";
import useGetIncomeStatementQuery from "../../../../hooks/queriesAndMutations/useGetIncomeStatementQuery";
import Section from "../../Section";
import SectionTitle from "../../../SectionTitle";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import Summary from "./Summary";
import styles from "./styles.module.css";

function Report() {
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    const { data: incomeStatementData } = useGetIncomeStatementQuery();

    if (!incomeStatementData) return null;

    const operatingRevenues = incomeStatementData.operating_revenues.record;

    const totalNetSales = incomeStatementData.total_net_sales.record;

    const costOfGoodsSold = incomeStatementData.cost_of_goods_sold.record;

    const grossProfitMargin = incomeStatementData.gross_profit.percentage;
    const grossProfit = incomeStatementData.gross_profit.record;

    const operatingExpenses = incomeStatementData.operating_expenses.record;

    const totalOperatingProfit =
        incomeStatementData.total_operating_profit.record;

    const otherExpenses = incomeStatementData.other_expenses.record;

    const otherIncome = incomeStatementData.other_income.record;

    const netProfitMargin = incomeStatementData.net_profit.percentage;
    const netProfit = incomeStatementData.net_profit.record;

    return (
        <div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Income Statement Report</SectionTitle>
            </div>
            <div className={styles.controlsContainer}>
                <CustomDateRangePicker
                    dateRange={dateRange}
                    onChange={setDateRange}
                    maxDate={maxDate}
                />
                <ExportReportButton />
            </div>
            <div className={styles.summaryContainer}>
                <Summary />
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name={operatingRevenues.name}
                    totalAmountCents={operatingRevenues.total_amount_cents}
                >
                    {operatingRevenues.children}
                </Section>
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name="Total Net Sales"
                    totalAmountCents={totalNetSales.total_amount_cents}
                />
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name={costOfGoodsSold.name}
                    totalAmountCents={costOfGoodsSold.total_amount_cents}
                >
                    {costOfGoodsSold.children}
                </Section>
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name={grossProfit.name}
                    totalAmountCents={grossProfit.total_amount_cents}
                    percentage={grossProfitMargin}
                    percentageTooltip={
                        <div>
                            <p>
                                Gross Profit Margin = Gross Profit / Total Net
                                Sales
                            </p>
                            <p>What does this mean?</p>
                            <p>
                                For each dollar of revenue, how much is left
                                after paying off Cost of Goods Sold (COGS).
                            </p>
                        </div>
                    }
                />
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name={operatingExpenses.name}
                    totalAmountCents={operatingExpenses.total_amount_cents}
                >
                    {operatingExpenses.children}
                </Section>
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name="Total Operating Profit"
                    totalAmountCents={totalOperatingProfit.total_amount_cents}
                />
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name={otherExpenses.name}
                    totalAmountCents={otherExpenses.total_amount_cents}
                >
                    {otherExpenses.children}
                </Section>
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name={otherIncome.name}
                    totalAmountCents={otherIncome.total_amount_cents}
                >
                    {otherIncome.children}
                </Section>
            </div>
            <div className={styles.sectionContainer}>
                <Section
                    name={netProfit.name}
                    totalAmountCents={netProfit.total_amount_cents}
                    percentage={netProfitMargin}
                    percentageTooltip={
                        <div>
                            <p>
                                Net Profit Margin = Net Profit / Total Net Sales
                            </p>
                            <p>What does this mean?</p>
                            <p>
                                For each dollar of revenue, how much is left
                                after all expenses are paid including COGS,
                                Operating Expenses, and Other Expenses.
                            </p>
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default Report;
