import React from "react";
import SectionTitle from "../../SectionTitle";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetCashFlowGraph from "./NetCashFlowGraph";
import Report from "./Report";
import styles from "./styles.module.css";

function CashFlow() {
    return (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.validUpUntilContainer}>
                <ValidUpUntil />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Net Cash Flow by Month</SectionTitle>
            </div>
            <div className={styles.netCashFlowGraphContainer}>
                <NetCashFlowGraph />
            </div>
            <Report />
        </div>
    );
}

export default CashFlow;
