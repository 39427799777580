import React from "react";
import OnboardingSvg from "./Onboarding.svg";
import SectionTitle from "../../SectionTitle";
import SignupAndScheduleDemo from "../SignupAndScheduleDemo";
import styles from "./styles.module.css";

function Onboarding() {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <img
                    src={OnboardingSvg}
                    alt="Onboarding"
                    className={styles.image}
                />
            </div>
            <div className={styles.spacer} />
            <div className={styles.content}>
                <div className={styles.sectionTitleContainer}>
                    <SectionTitle>
                        A White-Glove Onboarding Experience
                    </SectionTitle>
                </div>
                <div className={styles.stepsContainer}>
                    <div className={styles.step}>
                        <div className={styles.circle}>1</div>
                        <p className={styles.stepText}>
                            Apply online within 10 minutes or less.
                        </p>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.circle}>2</div>
                        <p className={styles.stepText}>
                            Get approved in as fast as 24 hours.
                        </p>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.circle}>3</div>
                        <p className={styles.stepText}>
                            Mercury will help you migrate your payments,
                            payroll, and other services from your previous bank
                            to Mercury.
                        </p>
                    </div>
                    <div className={styles.step}>
                        <div className={styles.circle}>4</div>
                        <p className={styles.stepText}>
                            Receive special bonuses on deposits and card spend.
                        </p>
                    </div>
                </div>
                <div className={styles.buttonsContainer}>
                    <SignupAndScheduleDemo />
                </div>
            </div>
        </div>
    );
}

export default Onboarding;
