import React from "react";
import Card from "../../Card";
import FreeAccountsSvg from "./FreeAccounts.svg";

function FreeAccounts() {
    return (
        <Card
            imageSrc={FreeAccountsSvg}
            title="$0 To Open Accounts"
            body="Quickly open up FDIC-insured checking and savings accounts. No monthly maintenance fees and no overdraft fees."
        />
    );
}

export default FreeAccounts;
