import React, { useState } from "react";
import PropTypes from "prop-types";
import useClickOutside from "../../hooks/useClickOutside";
import Dropdown from "./Dropdown";
import Display from "./Display";
import styles from "./styles.module.css";

function DatePicker({ date, onChange, maxDate }) {
    const [showDropdown, setShowDropdown] = useState(false);

    const onClickOutside = () => {
        setShowDropdown(false);
    };

    const containerRef = useClickOutside(onClickOutside);

    const onClick = () => {
        setShowDropdown(!showDropdown);
    };

    const onDateChange = (newDate) => {
        onChange(newDate);
        setShowDropdown(false);
    };

    return (
        <div ref={containerRef} className={styles.container}>
            <Display date={date} onClick={onClick} />
            {showDropdown && (
                <Dropdown
                    date={date}
                    onChange={onDateChange}
                    maxDate={maxDate}
                />
            )}
        </div>
    );
}

DatePicker.propTypes = {
    date: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default DatePicker;
