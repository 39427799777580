import { useParams } from "react-router-dom";
import { useGetProviderQuery as useAdminGetProviderQuery } from "../../../redux/slices/adminApi";
import { useGetProviderQuery as useProviderGetProviderQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetProviderQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetProviderQuery(providerId);
    }

    return useProviderGetProviderQuery();
};

export default useGetProviderQuery;
