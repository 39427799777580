import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import FinancialReportsSvg from "../../../assets/FinancialReports";
import SavingsSvg from "../../../assets/Savings";
import CapitalSvg from "../../../assets/Capital";
import Card from "./Card";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import styles from "./styles.module.css";

const cards = [
    {
        icon: <FinancialReportsSvg fill="black" />,
        title: "Track Your Key Financial Metrics",
        body: "Buyers strongly consider the monthly and yearly net profit of your business, as it determines how long it will take them to recoup their investment. In addition, net profit is a good gauge of how efficiently you're running your practice.",
        navigationPath: "/",
    },
    {
        icon: <SavingsSvg fill="black" />,
        title: "Reduce Your Expenses",
        body: "Expenses as a percentage of revenue is an important metric that buyers track. Buyers want to know that your expenses are in line with the standards in your specialty. If your expenses are too high, this could indicate inefficiencies in your business.",
        navigationPath: "/savings",
    },
    {
        icon: <CapitalSvg fill="black" />,
        title: "Refinance Your Debt",
        body: "Buyers look at your debt-to-income ratio to ensure that your business is not over-leveraged. If you have outstanding debt with high interest, you should consider refinancing to more affordable options with longer repayment periods.",
        navigationPath: "/capital/termLoan",
    },
];

const getCards = (providerId, isAdminDashboard) =>
    cards.map((card) => {
        let { navigationPath } = card;

        if (isAdminDashboard) {
            navigationPath = `/provider/${providerId}${navigationPath}`;
        }

        return (
            <Card
                icon={card.icon}
                title={card.title}
                body={card.body}
                navigationPath={navigationPath}
            />
        );
    });

function ImproveFinancialHealth() {
    const { providerId } = useParams();

    const isAdminDashboard = useIsAdminDashboard();

    const cardsWithNavigationPath = getCards(providerId, isAdminDashboard);

    return (
        <div className={styles.container}>
            <div className={styles.titleSection}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>
                        Increase your valuation by improving your financial
                        health.
                    </PageTitle>
                </div>
                <div className={styles.subtitle}>
                    The financial health of your business is the number one
                    factor that affects your valuation. Take the following steps
                    on our platform to improve your financial health.
                </div>
            </div>
            {cardsWithNavigationPath.map((card, i) => (
                <div className={styles.cardContainer} key={i}>
                    {card}
                </div>
            ))}
        </div>
    );
}

export default ImproveFinancialHealth;
