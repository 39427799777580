import React, { useState } from "react";
import Button from "../../Button";
import Card from "../../Card";
import CalendlyModal from "../../CalendlyModal";
import { CALENDLY_URLS } from "../../CalendlyModal/constants";
import PageTitle from "../../PageTitle";
import Video from "../../Video";
import MOCK_CASH_FLOW_REPORT from "./mockData";
import Section from "../../FinancialReports/CashFlow/Report/Section";
import styles from "./styles.module.css";

const FLYCHAIN_DEMO_URL =
    "https://www.loom.com/embed/fb48afdb1e0840c4b4bfbd7d68e963c2";

function Content() {
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    const inflows = MOCK_CASH_FLOW_REPORT.inflows.record;
    const outflows = MOCK_CASH_FLOW_REPORT.outflows.record;

    return (
        <div className={styles.container}>
            <div className={styles.sectionContainer}>
                <Section name={inflows.name}>{inflows.children}</Section>
            </div>
            <div className={styles.sectionContainer}>
                <Section name={outflows.name}>{outflows.children}</Section>
            </div>
            <div className={styles.overlay}>
                <div className={styles.cardContainer}>
                    <Card showTab tabText="Schedule Demo" tabColor="green">
                        <div>
                            <div className={styles.pageTitleContainer}>
                                <PageTitle>
                                    The Financial Operating System for Your
                                    Practice
                                </PageTitle>
                            </div>
                            <div className={styles.subtitle}>
                                Optimize the financial health of your practice
                                with our suite of financial tools. Let us handle
                                your financial workflows so you can spend less
                                time in spreadsheets and more time with
                                patients.
                            </div>
                            <div className={styles.videoContainer}>
                                <div className={styles.video}>
                                    <Video
                                        src={FLYCHAIN_DEMO_URL}
                                        title="Flychain Demo"
                                    />
                                </div>
                            </div>
                            <div className={styles.buttonContainer}>
                                <Button
                                    color="primary"
                                    onClick={() => setShowCalendlyModal(true)}
                                >
                                    Schedule a Demo
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.FLYCHAIN_TEAM}
                />
            )}
        </div>
    );
}

export default Content;
