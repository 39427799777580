import React from "react";
import { PropTypes } from "prop-types";
import PlusSvg from "../../../../../../assets/Plus.svg";
import styles from "./styles.module.css";

function PlusButton({ onClick }) {
    return (
        <div className={styles.container} onClick={onClick}>
            <img className={styles.icon} src={PlusSvg} alt="Plus" />
        </div>
    );
}

PlusButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default PlusButton;
