import { useParams } from "react-router-dom";
import { useGetMercurySignupLinkMutation as useAdminGetMercurySignupLinkMutation } from "../../../redux/slices/adminApi";
import { useGetMercurySignupLinkMutation as useProviderGetMercurySignupLinkMutation } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetMercurySignupLinkMutation = () => {
    const isAdminDashboard = useIsAdminDashboard();
    const { providerId } = useParams();

    const [adminTrigger, adminResult, adminReset] =
        useAdminGetMercurySignupLinkMutation();

    const [providerTrigger, providerResult, providerReset] =
        useProviderGetMercurySignupLinkMutation();

    const trigger = (inviteEmail) => {
        const body = {
            invite_email: inviteEmail,
        };

        if (isAdminDashboard) {
            return adminTrigger({
                providerId,
                body,
            });
        }

        return providerTrigger(body);
    };

    return [
        trigger,
        isAdminDashboard ? adminResult : providerResult,
        isAdminDashboard ? adminReset : providerReset,
    ];
};

export default useGetMercurySignupLinkMutation;
