import React from "react";
import Card from "../../Card";
import MultiEinSvg from "./MultiEin.svg";

function MultiEin() {
    return (
        <Card
            imageSrc={MultiEinSvg}
            title="Multi-EIN Support"
            body="Seamless support for multi-EIN practices to handle even the most complicated corporate structures."
        />
    );
}

export default MultiEin;
