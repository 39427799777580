import React from "react";
import LiveAdvancedPaymentOnClaims from "./LiveAdvancedPaymentOnClaims";
import useGetLineForAdvancedPaymentOnClaimsQuery from "../../../hooks/queriesAndMutations/useGetLineForAdvancedPaymentOnClaimsQuery";
import useGetPartnerQuery from "../../../hooks/queriesAndMutations/useGetPartnerQuery";
import Landing from "./Landing";
import VayaLanding from "./VayaLanding";
import PARTNERS from "../../../partners/constants";

const displayPage = (partner, line) => {
    if (partner.internal_name === PARTNERS.HEADSTART) {
        return <VayaLanding />;
    }

    if (line.id) {
        return <LiveAdvancedPaymentOnClaims />;
    }

    return <Landing />;
};

function AdvancedPaymentOnClaims() {
    const { data: partner } = useGetPartnerQuery();
    const { data: line } = useGetLineForAdvancedPaymentOnClaimsQuery();

    if (!partner || !line) {
        return null;
    }

    return displayPage(partner, line);
}

export default AdvancedPaymentOnClaims;
