import React from "react";
import { useParams } from "react-router-dom";
import NavigationTab from "../../../SideBar/NavigationTab";
import CapitalSvg from "../../../../assets/Capital";
import FinancialReportsSvg from "../../../../assets/FinancialReports";
import ConnectionsSvg from "../../../../assets/Connections";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import { financialReportsSubtabs } from "../../../SideBar/constants";

// TODO: change specific icons for each tab.
function ProviderSelected() {
    const { providerId } = useParams();
    let { businessEntityId } = useParams();

    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    businessEntityId =
        businessEntityId || (businessEntities && businessEntities[0].id);

    if (!businessEntityId) return null;

    return (
        <>
            <NavigationTab
                label="Financials"
                path={`/provider/${providerId}/businessEntity/${businessEntityId}/financialReports`}
                icon={<FinancialReportsSvg fill="white" />}
                subtabs={financialReportsSubtabs}
            />
            <NavigationTab
                label="Capital"
                path={`/provider/${providerId}/capital/advancedPaymentOnClaims`}
                icon={<CapitalSvg fill="white" />}
            />
            <NavigationTab
                label="Connections"
                path={`/provider/${providerId}/connections`}
                icon={<ConnectionsSvg fill="white" />}
            />
        </>
    );
}

export default ProviderSelected;
