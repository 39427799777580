import React from "react";
import HeaderSection from "../../HeaderSection";
import ProductSummary, { productSummaryTitles } from "../../ProductSummary";
import QualificationRequirements, {
    qualificationRequirementsTitles,
} from "../../QualificationRequirements";
import ARGraphicSvg from "../../../../assets/ARGraphic.svg";
import styles from "./styles.module.css";

const productSummaryRows = [
    [
        productSummaryTitles.USE_OF_FUNDS,
        "Funds can be used for any business purpose",
    ],
    [productSummaryTitles.LOAN_AMOUNT, "$1,000 to $1,000,000"],
    [productSummaryTitles.TIME_TO_FUND, "Funding as soon as 2 business days"],
    [productSummaryTitles.REPAYMENT_PERIOD, "1-4 months"],
    [
        productSummaryTitles.INTEREST_RATE,
        "16-25% annualized interest rate depending on your credit profile",
    ],
];

const qualificationRequirementsRows = [
    [qualificationRequirementsTitles.TIME_IN_BUSINESS, "None"],
    [qualificationRequirementsTitles.FICO, "None"],
    [qualificationRequirementsTitles.CLAIMS, "At least 1 week of claims data"],
];

function VayaLanding() {
    const onClick = () => {
        window.open(
            "https://flychain.express.embedvaya.com/login?c=2",
            "_blank"
        );
    };
    return (
        <div className={styles.container}>
            <HeaderSection
                title="Advanced Payment on Claims"
                body="Receive a lump-sum advance on your outstanding claims to meet your cash flow needs. Almost all our providers qualify and we fund within two business days."
                buttonText="Get Started"
                onClick={onClick}
                graphic={
                    <img
                        className={styles.graphic}
                        src={ARGraphicSvg}
                        alt="ARGraphicSvg"
                    />
                }
            />
            <div className={styles.pageContainer}>
                <div className={styles.tableContainer}>
                    <ProductSummary rows={productSummaryRows} />
                </div>
                <QualificationRequirements
                    rows={qualificationRequirementsRows}
                />
            </div>
        </div>
    );
}

export default VayaLanding;
