import React from "react";
import FlychainLogoSvg from "../../../../assets/FlychainLogo.svg";
import MercuryLogoSvg from "../../../../assets/MercuryLogo.svg";
import styles from "./styles.module.css";

function Logos() {
    return (
        <div className={styles.container}>
            <img
                src={FlychainLogoSvg}
                alt="FlychainLogo"
                className={styles.logo}
            />
            <span className={styles.separator}>x</span>
            <img
                src={MercuryLogoSvg}
                alt="MercuryLogo"
                className={styles.logo}
            />
        </div>
    );
}

export default Logos;
