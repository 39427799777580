import React from "react";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import Report from "./Report";
import styles from "./styles.module.css";

function BalanceSheet() {
    return (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.validUpUntilContainer}>
                <ValidUpUntil />
            </div>
            <Report />
        </div>
    );
}

export default BalanceSheet;
