import { useParams } from "react-router-dom";
import { useLazyDownloadCashFlowReportAsyncQuery as useAdminLazyDownloadCashFlowReportAsyncQuery } from "../../../redux/slices/adminApi";
import { useLazyDownloadCashFlowReportAsyncQuery as useProviderLazyDownloadCashFlowReportAsyncQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useLazyDownloadCashFlowReportAsyncQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const [adminTrigger, adminResult] =
        useAdminLazyDownloadCashFlowReportAsyncQuery();

    const [providerTrigger, providerResult] =
        useProviderLazyDownloadCashFlowReportAsyncQuery();

    const { providerId, businessEntityId } = useParams();

    const preloadParams = isAdminDashboard
        ? { providerId, businessEntityId }
        : { businessEntityId };

    const triggerWithParams = (params) => {
        const finalParams = { ...preloadParams, ...params };

        if (isAdminDashboard) {
            return adminTrigger(finalParams);
        }

        return providerTrigger(finalParams);
    };

    return [triggerWithParams, isAdminDashboard ? adminResult : providerResult];
};

export default useLazyDownloadCashFlowReportAsyncQuery;
