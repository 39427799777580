import React from "react";
import PropTypes from "prop-types";
import Line from "../../../Line";

const renderChild = (child, lastChild) => {
    const key = child.ledger_id || child.name;

    return (
        <Line
            key={key}
            ledgerId={child.ledger_id || undefined}
            name={child.name}
            totalAmountCents={child.total_amount_cents}
            numberOfIndents={1}
            position={lastChild ? "bottom" : "middle"}
        />
    );
};

function Section({ name, children }) {
    if (children.length === 0) {
        return <Line name={name} numberOfIndents={0} position="single" />;
    }

    return (
        <div>
            <Line name={name} numberOfIndents={0} position="top" />
            {children.map((child, i) =>
                renderChild(child, i === children.length - 1)
            )}
        </div>
    );
}

Section.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default Section;
