import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    selectDateRange,
    serializeDateRange,
    updateDateRange,
} from "../../../redux/slices/dateRange";
import useGetRevenueByCategoryQuery from "../../../hooks/queriesAndMutations/useGetRevenueByCategoryQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import LedgerTable from "../LedgerTable";
import CustomDateRangePicker from "../../CustomDateRangePicker";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import BusinessEntitySelect from "../BusinessEntitySelect";
import StackedBarGraph from "./StackedBarGraph";
import SectionTitle from "../../SectionTitle";
import PageTitle from "../../PageTitle";
import styles from "./styles.module.css";

const HEADERS = ["Category", "Percentage of Revenue", "Total Revenue", ""];

function Revenue() {
    const { data: revenueData } = useGetRevenueByCategoryQuery();

    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    return revenueData ? (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.pageTitleContainer}>
                <PageTitle>Revenue By Category</PageTitle>
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>By Month</SectionTitle>
            </div>
            <div className={styles.stackedBarGraphContainer}>
                <StackedBarGraph />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>For Date Range</SectionTitle>
            </div>
            <div className={styles.customDateRangePickerContainer}>
                <CustomDateRangePicker
                    dateRange={dateRange}
                    onChange={setDateRange}
                    maxDate={maxDate}
                />
            </div>
            <LedgerTable data={revenueData} headers={HEADERS} />
        </div>
    ) : null;
}

export default Revenue;
