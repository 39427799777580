import dayjs from "dayjs";
import { isDateInRange } from "../../utils";

const getDateRangeForMonth = (year, month, maxDate) => {
    const startDate = dayjs()
        .set("year", year)
        .set("month", month)
        .startOf("month");
    let endDate = startDate.endOf("month");

    if (isDateInRange(startDate, endDate, maxDate)) {
        endDate = maxDate.endOf("day");
    }

    return {
        startDate,
        endDate,
    };
};

export default getDateRangeForMonth;
