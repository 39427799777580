import REPORT_TYPES from "../../components/FinancialReports/constants";
import useLazyDownloadIncomeStatementAsyncQuery from "../queriesAndMutations/useLazyDownloadIncomeStatementAsyncQuery";
import useLazyDownloadCashFlowReportAsyncQuery from "../queriesAndMutations/useLazyDownloadCashFlowReportAsyncQuery";
import useLazyDownloadBalanceSheetAsyncQuery from "../queriesAndMutations/useLazyDownloadBalanceSheetAsyncQuery";
import useLazyDownloadExpensesByCategoryAsyncQuery from "../queriesAndMutations/useLazyDownloadExpensesByCategoryAsyncQuery";
import useGetReportType from "../useGetReportType";

const useLazyDownloadReportAsyncQuery = () => {
    const reportType = useGetReportType();

    switch (reportType) {
        case REPORT_TYPES.INCOME_STATEMENT:
            return useLazyDownloadIncomeStatementAsyncQuery();
        case REPORT_TYPES.CASH_FLOW:
            return useLazyDownloadCashFlowReportAsyncQuery();
        case REPORT_TYPES.BALANCE_SHEET:
            return useLazyDownloadBalanceSheetAsyncQuery();
        case REPORT_TYPES.EXPENSES_BY_CATEGORY:
            return useLazyDownloadExpensesByCategoryAsyncQuery();
        default:
            throw new Error(`Unknown report type: ${reportType}`);
    }
};

export default useLazyDownloadReportAsyncQuery;
