import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import LinesTable from "./LinesTable";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import {
    selectDateRange,
    updateDateRange,
    serializeDateRange,
} from "../../../../redux/slices/dateRange";
import getDateRangeForAllTimeToEndDate from "../utils";
import { getCurrencyString } from "../../../../utils";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import RecategorizeTransactions from "./RecategorizeTransactions";
import ExportReportButton from "../../ExportReportButton";
import useGetReportType from "../../../../hooks/useGetReportType";
import REPORT_TYPES from "../../constants";
import LabeledText from "../../../LabeledText";
import styles from "./styles.module.css";

function Lines({ ledgerLinesData }) {
    const reportType = useGetReportType();

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    const dateRange =
        reportType === REPORT_TYPES.BALANCE_SHEET
            ? getDateRangeForAllTimeToEndDate(validUpUntilData.valid_up_until)
            : useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    return (
        <div>
            {reportType !== REPORT_TYPES.BALANCE_SHEET && (
                <div className={styles.customDateRangePickerContainer}>
                    <CustomDateRangePicker
                        dateRange={dateRange}
                        onChange={setDateRange}
                        maxDate={maxDate}
                    />
                </div>
            )}
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label={`${ledgerLinesData.ledger_name} Total`}
                    text={getCurrencyString(
                        ledgerLinesData.ledger_amount_cents
                    )}
                />
                {
                    // TODO: remove the casing below once we implement export for revenue by category
                }
                {reportType !== REPORT_TYPES.REVENUE_BY_CATEGORY && (
                    <ExportReportButton />
                )}
            </div>
            <div className={styles.recategorizeTransactionsContainer}>
                <RecategorizeTransactions />
            </div>
            <LinesTable lines={ledgerLinesData.ledger_lines} />
        </div>
    );
}

Lines.propTypes = {
    ledgerLinesData: PropTypes.object.isRequired,
};

export default Lines;
