import React from "react";
import useGetDataForFinancialReportsSummaryQuery from "../../../../../hooks/queriesAndMutations/useGetDataForFinancialReportsSummaryQuery";
import LabeledText from "../../../../LabeledText";
import { getCurrencyString } from "../../../../../utils";
import styles from "./styles.module.css";

function Summary() {
    const { data } = useGetDataForFinancialReportsSummaryQuery();

    return data ? (
        <div className={styles.container}>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label="Total Revenue"
                    text={getCurrencyString(data.total_revenue_cents)}
                />
            </div>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label="Total Expenses"
                    text={getCurrencyString(data.total_expenses_cents)}
                />
            </div>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label="Net Profit"
                    text={getCurrencyString(data.net_profit_cents)}
                />
            </div>
            {data.total_revenue_cents !== 0 ? (
                <div className={styles.labeledTextContainer}>
                    <LabeledText
                        label="Net Profit Margin"
                        text={`${data.percent_profit_margin.toFixed(2)}%`}
                    />
                </div>
            ) : null}
        </div>
    ) : null;
}

export default Summary;
