import React from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    selectDateRange,
    serializeDateRange,
    updateDateRange,
} from "../../../../redux/slices/dateRange";
import ExportReportButton from "../../ExportReportButton";
import useGetDataForTopExpensesGraphQuery from "../../../../hooks/queriesAndMutations/useGetDataForTopExpensesGraphQuery";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import LedgerTable from "../../LedgerTable";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import styles from "./styles.module.css";

const HEADERS = ["Category", "Percentage of Spend", "Total Spend", ""];

function ExpensesByCategory() {
    const { data: expenseData } = useGetDataForTopExpensesGraphQuery();

    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();
    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    return expenseData ? (
        <div className={styles.container}>
            <div className={styles.controlsContainer}>
                <CustomDateRangePicker
                    dateRange={dateRange}
                    onChange={setDateRange}
                    maxDate={maxDate}
                />
                {isAdminDashboard && <ExportReportButton />}
            </div>
            <LedgerTable data={expenseData} headers={HEADERS} />
        </div>
    ) : null;
}

export default ExpensesByCategory;
