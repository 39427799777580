import React from "react";
import Card from "../../Card";
import DepositBonusSvg from "./DepositBonus.svg";

function DepositBonus() {
    return (
        <Card
            imageSrc={DepositBonusSvg}
            title="$500 Cash Bonus"
            body="Enjoy a $500 cash bonus after depositing $10K into Mercury within 90 days of opening your account."
        />
    );
}

export default DepositBonus;
