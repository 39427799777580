import React from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import SectionTitle from "../../../SectionTitle";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import ExportReportButton from "../../ExportReportButton";
import Summary from "./Summary";
import {
    updateDateRange,
    serializeDateRange,
    selectDateRange,
} from "../../../../redux/slices/dateRange";
import useGetCashFlowReportQuery from "../../../../hooks/queriesAndMutations/useGetCashFlowReportQuery";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import Section from "./Section";
import styles from "./styles.module.css";

function Report() {
    const dateRange = useSelector(selectDateRange);
    const dispatch = useDispatch();
    const setDateRange = (newDateRange) => {
        dispatch(updateDateRange(serializeDateRange(newDateRange)));
    };

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const isAdminDashboard = useIsAdminDashboard();
    const maxDate = isAdminDashboard
        ? dayjs()
        : dayjs(validUpUntilData.valid_up_until);

    const { data: cashFlowReportData } = useGetCashFlowReportQuery();

    if (!cashFlowReportData) return null;

    const inflows = cashFlowReportData.inflows.record;
    const outflows = cashFlowReportData.outflows.record;

    return (
        <div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Cash Flow Report</SectionTitle>
            </div>
            <div className={styles.controlsContainer}>
                <CustomDateRangePicker
                    dateRange={dateRange}
                    onChange={setDateRange}
                    maxDate={maxDate}
                />
                <ExportReportButton />
            </div>
            <div className={styles.summaryContainer}>
                <Summary data={cashFlowReportData} />
            </div>
            <div className={styles.sectionContainer}>
                <Section name={inflows.name}>{inflows.children}</Section>
            </div>
            <div className={styles.sectionContainer}>
                <Section name={outflows.name}>{outflows.children}</Section>
            </div>
        </div>
    );
}

export default Report;
