import dayjs from "dayjs";

export const isDateInRange = (startDate, endDate, dateToCheck) =>
    dateToCheck.isSame(startDate, "day") ||
    dateToCheck.isSame(endDate, "day") ||
    (dateToCheck.isAfter(startDate, "day") &&
        dateToCheck.isBefore(endDate, "day"));

export const getDateRangeForAnnual = (year, maxDate) => {
    const startDate = dayjs().set("year", year).startOf("year");
    let endDate = startDate.endOf("year");

    if (isDateInRange(startDate, endDate, maxDate)) {
        endDate = maxDate.endOf("day");
    }

    return {
        startDate,
        endDate,
    };
};
