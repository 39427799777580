import React, { useState } from "react";
import PropTypes from "prop-types";
import YearPicker from "./YearPicker";
import Periods from "./Periods";
import PERIODS from "./Periods/constants";
import MonthPicker from "./MonthPicker";
import QuarterPicker from "./QuarterPicker";
import { getDateRangeForAnnual } from "./utils";
import styles from "./styles.module.css";

function Dropdown({ dateRange, onChange, maxDate }) {
    const [period, setPeriod] = useState(PERIODS.MONTHLY);

    const [year, setYear] = useState(dateRange.startDate.year());

    const onPeriodChange = (newPeriod) => {
        if (newPeriod === PERIODS.ANNUAL) {
            const newDateRange = getDateRangeForAnnual(year, maxDate);
            onChange(newDateRange);
        } else {
            setPeriod(newPeriod);
        }
    };

    return (
        <div className={styles.container}>
            <YearPicker year={year} onChange={setYear} maxDate={maxDate} />
            <div className={styles.periodsContainer}>
                <Periods period={period} onChange={onPeriodChange} />
            </div>
            {period === PERIODS.MONTHLY && (
                <div className={styles.pickerContainer}>
                    <MonthPicker
                        year={year}
                        onChange={onChange}
                        maxDate={maxDate}
                    />
                </div>
            )}
            {period === PERIODS.QUARTERLY && (
                <div className={styles.pickerContainer}>
                    <QuarterPicker
                        year={year}
                        onChange={onChange}
                        maxDate={maxDate}
                    />
                </div>
            )}
        </div>
    );
}

Dropdown.propTypes = {
    dateRange: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default Dropdown;
