import React from "react";
import HeaderSection from "./HeaderSection";
import Disclaimers from "./Disclaimers";
import MainBenefits from "./MainBenefits";
import ExclusiveBenefits from "./ExclusiveBenefits";
import Onboarding from "./Onboarding";

function Banking() {
    return (
        <div>
            <HeaderSection />
            <MainBenefits />
            <ExclusiveBenefits />
            <Onboarding />
            <Disclaimers />
        </div>
    );
}

export default Banking;
