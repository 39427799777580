import { useParams } from "react-router-dom";
import { useGetPartnerQuery as useAdminGetPartnerQuery } from "../../../redux/slices/adminApi";
import { useGetPartnerQuery as useProviderGetPartnerQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetPartnerQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetPartnerQuery(providerId);
    }

    return useProviderGetPartnerQuery();
};

export default useGetPartnerQuery;
