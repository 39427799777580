import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../SectionTitle";
import styles from "./styles.module.css";

function Table({ title, rows }) {
    return (
        <div className={styles.container}>
            <SectionTitle>{title}</SectionTitle>
            <div className={styles.divider} />
            {rows.map(([rowTitle, body]) => (
                <div key={rowTitle} className={styles.row}>
                    <div className={styles.rowTitle}>{rowTitle}</div>
                    <div className={styles.body}>{body}</div>
                </div>
            ))}
        </div>
    );
}

Table.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    ).isRequired,
};

export default Table;
