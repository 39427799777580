import React from "react";
import { useParams } from "react-router-dom";
import NavigationTab from "../../../SideBar/NavigationTab";
import CapitalSvg from "../../../../assets/Capital";
import FinancialReportsSvg from "../../../../assets/FinancialReports";
import ConnectionsSvg from "../../../../assets/Connections";
import BankSvg from "../../../../assets/Bank";
import SavingsSvg from "../../../../assets/Savings";
import ValuationSvg from "../../../../assets/Valuation";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import {
    financialReportsSubtabs,
    bankingSubtabs,
    onboardingFlowSubtabs,
} from "../../../SideBar/constants";
import useGetCapitalSubtabs from "../../../../hooks/useGetCapitalSubtabs";

// TODO: change specific icons for each tab.
function ProviderSelected() {
    const { providerId } = useParams();
    let { businessEntityId } = useParams();

    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    businessEntityId =
        businessEntityId || (businessEntities && businessEntities[0].id);

    const capitalSubtabs = useGetCapitalSubtabs();

    if (!businessEntityId || !capitalSubtabs) return null;

    return (
        <>
            <NavigationTab
                label="Financials"
                path={`/provider/${providerId}/businessEntity/${businessEntityId}/financialReports`}
                icon={<FinancialReportsSvg fill="white" />}
                subtabs={financialReportsSubtabs}
            />
            <NavigationTab
                label="Capital"
                path={`/provider/${providerId}/capital`}
                icon={<CapitalSvg fill="white" />}
                subtabs={capitalSubtabs}
            />
            <NavigationTab
                label="Valuation"
                path={`/provider/${providerId}/valuation`}
                icon={<ValuationSvg fill="white" />}
            />
            <NavigationTab
                label="Banking"
                path={`/provider/${providerId}/banking`}
                icon={<BankSvg fill="white" />}
                subtabs={bankingSubtabs}
            />
            <NavigationTab
                label="Savings"
                path={`/provider/${providerId}/savings`}
                icon={<SavingsSvg fill="white" />}
            />
            <NavigationTab
                label="Connections"
                path={`/provider/${providerId}/connections`}
                icon={<ConnectionsSvg fill="white" />}
            />
            <NavigationTab
                label="Onboarding"
                path={`/provider/${providerId}/onboard`}
                icon={<ConnectionsSvg fill="white" />}
                subtabs={onboardingFlowSubtabs}
            />
        </>
    );
}

export default ProviderSelected;
