import React from "react";
import PropTypes from "prop-types";
import MonthButton from "./MonthButton";
import styles from "./styles.module.css";

const MONTHS_PER_ROW = 4;

function MonthPicker({ year, onChange, maxDate }) {
    const monthButtons = Array.from({ length: 12 }, (_, i) => (
        <div className={styles.monthButtonContainer} key={i}>
            <MonthButton
                year={year}
                month={i}
                onClick={onChange}
                maxDate={maxDate}
            />
        </div>
    ));

    const rows = Array.from(
        { length: Math.ceil(monthButtons.length / MONTHS_PER_ROW) },
        (_, rowIndex) => (
            <div className={styles.row} key={rowIndex}>
                {monthButtons.slice(
                    rowIndex * MONTHS_PER_ROW,
                    (rowIndex + 1) * MONTHS_PER_ROW
                )}
            </div>
        )
    );

    return <div className={styles.container}>{rows}</div>;
}

MonthPicker.propTypes = {
    year: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default MonthPicker;
