const MOCK_CASH_FLOW_REPORT = {
    ending_cash_balance: {
        record: {
            amount_cents: 214081275,
            children: [],
            debit_credit: null,
            ledger_id: null,
            name: "Ending Cash Balance",
            sort_code: null,
            sub_type: null,
            total_amount_cents: 214081275,
            type: null,
        },
    },
    inflows: {
        record: {
            amount_cents: 3609573,
            children: [
                {
                    amount_cents: 1820761,
                    children: [
                        {
                            amount_cents: 197323,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 197323,
                            type: null,
                        },
                        {
                            amount_cents: 300074,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 300074,
                            type: null,
                        },
                        {
                            amount_cents: -1348,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -1348,
                            type: null,
                        },
                        {
                            amount_cents: 216402,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 216402,
                            type: null,
                        },
                        {
                            amount_cents: 145620,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 145620,
                            type: null,
                        },
                        {
                            amount_cents: -1524,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -1524,
                            type: null,
                        },
                        {
                            amount_cents: 450760,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 450760,
                            type: null,
                        },
                        {
                            amount_cents: -179800,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -179800,
                            type: null,
                        },
                        {
                            amount_cents: 113100,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 113100,
                            type: null,
                        },
                        {
                            amount_cents: 32886,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 32886,
                            type: null,
                        },
                        {
                            amount_cents: 58230,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 58230,
                            type: null,
                        },
                        {
                            amount_cents: 96526,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 96526,
                            type: null,
                        },
                        {
                            amount_cents: 394690,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 394690,
                            type: null,
                        },
                        {
                            amount_cents: -2178,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stripe",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2178,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "MvHih2RPqBaK6zknNQDQ2i",
                    name: "SaaS Revenue",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 1820761,
                    type: null,
                },
                {
                    amount_cents: 1099050,
                    children: [
                        {
                            amount_cents: 8137,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Mercury Io Cashback",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 8137,
                            type: null,
                        },
                        {
                            amount_cents: 685524,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Treasure Return - September 2024",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 685524,
                            type: null,
                        },
                        {
                            amount_cents: 391869,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "August net return",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 391869,
                            type: null,
                        },
                        {
                            amount_cents: 13520,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "FUNDEDBUY, Llc",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 13520,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "SHKvWjeLd9fmM7uQ5xYEpt",
                    name: "Other Income",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 1099050,
                    type: null,
                },
                {
                    amount_cents: 689762,
                    children: [
                        {
                            amount_cents: 689762,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Oat FINANCIAL, INC.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 689762,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "FAu1hAbrhrugb7P4DBoU5B",
                    name: "Funding  Revenue",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 689762,
                    type: null,
                },
                {
                    amount_cents: 0,
                    children: [
                        {
                            amount_cents: 542575,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Mercury Checking ••3711",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 542575,
                            type: null,
                        },
                        {
                            amount_cents: -542575,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Mercury Credit",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -542575,
                            type: null,
                        },
                        {
                            amount_cents: 2000000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Treasury",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 2000000,
                            type: null,
                        },
                        {
                            amount_cents: -2000000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Mercury Checking ••3711",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2000000,
                            type: null,
                        },
                        {
                            amount_cents: 3000000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Treasury",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: 3000000,
                            type: null,
                        },
                        {
                            amount_cents: -3000000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Mercury Checking ••3711",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3000000,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "6VLeUu4ewMhVpoRFDk2UYu",
                    name: "Transfers Between Accounts",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 0,
                    type: null,
                },
            ],
            debit_credit: null,
            ledger_id: null,
            name: "Cash Inflows",
            sort_code: null,
            sub_type: null,
            total_amount_cents: 3609573,
            type: null,
        },
    },
    net_cash_flow: {
        record: {
            amount_cents: -3549205,
            children: [],
            debit_credit: null,
            ledger_id: null,
            name: "Net Cash Flow",
            sort_code: null,
            sub_type: null,
            total_amount_cents: -3549205,
            type: null,
        },
    },
    outflows: {
        record: {
            amount_cents: 7158778,
            children: [
                {
                    amount_cents: -2000,
                    children: [
                        {
                            amount_cents: -2000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Delaware Corp & Tax We.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2000,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "FNxP3FbnrdWb2oaWbSgWhT",
                    name: "Licenses And Deductible Taxes Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 2000,
                    type: null,
                },
                {
                    amount_cents: -18000,
                    children: [
                        {
                            amount_cents: -1000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Ads",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -1000,
                            type: null,
                        },
                        {
                            amount_cents: -5000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Ads",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -5000,
                            type: null,
                        },
                        {
                            amount_cents: -12000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Referral-factory.com.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -12000,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "QTm9aSxxhkPKTNruME55JT",
                    name: "Advertising Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 18000,
                    type: null,
                },
                {
                    amount_cents: -22700,
                    children: [
                        {
                            amount_cents: -22700,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Flychain Lineage (Main)",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -22700,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "6Acvngn2QAuLjZxwYUj1qN",
                    name: "Financing Fee Credit",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 22700,
                    type: null,
                },
                {
                    amount_cents: -29340,
                    children: [
                        {
                            amount_cents: -29340,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Treasure Return - September 2024",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -29340,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "JeLGLWNDEZujgdTYiCxnnP",
                    name: "Bank Fees Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 29340,
                    type: null,
                },
                {
                    amount_cents: -60333,
                    children: [
                        {
                            amount_cents: -6728,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Pura Vida Nomad N.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -6728,
                            type: null,
                        },
                        {
                            amount_cents: -2069,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Tappo Location 1.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2069,
                            type: null,
                        },
                        {
                            amount_cents: -18815,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Casa Mono.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -18815,
                            type: null,
                        },
                        {
                            amount_cents: -2004,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Potbelly Sandwich Shop",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2004,
                            type: null,
                        },
                        {
                            amount_cents: -3033,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber Eats",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3033,
                            type: null,
                        },
                        {
                            amount_cents: -10826,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Eataly Ny Serra.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -10826,
                            type: null,
                        },
                        {
                            amount_cents: -2668,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Columbia Cafe Tpa.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2668,
                            type: null,
                        },
                        {
                            amount_cents: -1600,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber Eats",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -1600,
                            type: null,
                        },
                        {
                            amount_cents: -3507,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber Eats",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3507,
                            type: null,
                        },
                        {
                            amount_cents: -3699,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber Eats",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3699,
                            type: null,
                        },
                        {
                            amount_cents: -2783,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber Eats",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2783,
                            type: null,
                        },
                        {
                            amount_cents: -2601,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber Eats",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2601,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "3NNQFQGL6e1fk3SmhDeDe3",
                    name: "Business Meals Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 60333,
                    type: null,
                },
                {
                    amount_cents: -104500,
                    children: [
                        {
                            amount_cents: -79500,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stonebridge Advisory.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -79500,
                            type: null,
                        },
                        {
                            amount_cents: -25000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Stonebridge Advisory.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -25000,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "w33BiLucC8peNiwH3sd59",
                    name: "Professional Services Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 104500,
                    type: null,
                },
                {
                    amount_cents: -143750,
                    children: [
                        {
                            amount_cents: -118750,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Christine Foo Mei",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -118750,
                            type: null,
                        },
                        {
                            amount_cents: -25000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Blubooks.co.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -25000,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "8t4h3hBsaz1j5uAA2o7VdM",
                    name: "Contract Labor Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 143750,
                    type: null,
                },
                {
                    amount_cents: -159894,
                    children: [
                        {
                            amount_cents: -57,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -57,
                            type: null,
                        },
                        {
                            amount_cents: -453,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -453,
                            type: null,
                        },
                        {
                            amount_cents: -3990,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3990,
                            type: null,
                        },
                        {
                            amount_cents: -49496,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Expedia",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -49496,
                            type: null,
                        },
                        {
                            amount_cents: -20195,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Delta Air Lines",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -20195,
                            type: null,
                        },
                        {
                            amount_cents: -3978,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3978,
                            type: null,
                        },
                        {
                            amount_cents: -2196,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2196,
                            type: null,
                        },
                        {
                            amount_cents: -6006,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -6006,
                            type: null,
                        },
                        {
                            amount_cents: -5299,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -5299,
                            type: null,
                        },
                        {
                            amount_cents: -23625,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Bellwether Beach Resor.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -23625,
                            type: null,
                        },
                        {
                            amount_cents: -19003,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Bellwether Beach Resor.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -19003,
                            type: null,
                        },
                        {
                            amount_cents: -6420,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Bellwether Beach Resor.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -6420,
                            type: null,
                        },
                        {
                            amount_cents: -6095,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -6095,
                            type: null,
                        },
                        {
                            amount_cents: -2000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Delta Air Lines",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2000,
                            type: null,
                        },
                        {
                            amount_cents: -5894,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -5894,
                            type: null,
                        },
                        {
                            amount_cents: -3785,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3785,
                            type: null,
                        },
                        {
                            amount_cents: -1402,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Uber",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -1402,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "AP476oTZSu8HZCtM73sLPA",
                    name: "Travel And Transportation Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 159894,
                    type: null,
                },
                {
                    amount_cents: -330000,
                    children: [
                        {
                            amount_cents: -330000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Iss Facility Deb",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -330000,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "MuhT198ttSS7di4mUkMQXL",
                    name: "Rent",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 330000,
                    type: null,
                },
                {
                    amount_cents: -534112,
                    children: [
                        {
                            amount_cents: -7200,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Gsuite Flychai.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -7200,
                            type: null,
                        },
                        {
                            amount_cents: -8115,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Heroku",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -8115,
                            type: null,
                        },
                        {
                            amount_cents: -2178,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Openai",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2178,
                            type: null,
                        },
                        {
                            amount_cents: -3811,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Intuit",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3811,
                            type: null,
                        },
                        {
                            amount_cents: -27219,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Hubspot",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -27219,
                            type: null,
                        },
                        {
                            amount_cents: -3745,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Slack",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3745,
                            type: null,
                        },
                        {
                            amount_cents: -9000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Superhuman",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -9000,
                            type: null,
                        },
                        {
                            amount_cents: -4900,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Figma Monthly Renewal.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -4900,
                            type: null,
                        },
                        {
                            amount_cents: -3398,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Otter.ai.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3398,
                            type: null,
                        },
                        {
                            amount_cents: -3158,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Webflow.com.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3158,
                            type: null,
                        },
                        {
                            amount_cents: -104,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Github",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -104,
                            type: null,
                        },
                        {
                            amount_cents: -249375,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "PLAID",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -249375,
                            type: null,
                        },
                        {
                            amount_cents: -2178,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Notion Labs",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2178,
                            type: null,
                        },
                        {
                            amount_cents: -2000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Cursor Ai Powered Ide.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2000,
                            type: null,
                        },
                        {
                            amount_cents: -1307,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Github",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -1307,
                            type: null,
                        },
                        {
                            amount_cents: -3158,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Sentry.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3158,
                            type: null,
                        },
                        {
                            amount_cents: -200000,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Teal Platforms Inc",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -200000,
                            type: null,
                        },
                        {
                            amount_cents: -3266,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Calendly",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -3266,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "Wtktu3cPNXtaVWSyQ3Hc76",
                    name: "Software and Technology Expense",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 534112,
                    type: null,
                },
                {
                    amount_cents: -924600,
                    children: [
                        {
                            amount_cents: -924600,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "Blubooks Inc.",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -924600,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "SaiGP4f7VKSxxYEVGESuve",
                    name: "Accounts Payable",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 924600,
                    type: null,
                },
                {
                    amount_cents: -4829549,
                    children: [
                        {
                            amount_cents: -253053,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "C138333 Justwork",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -253053,
                            type: null,
                        },
                        {
                            amount_cents: -2300484,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "C138333 Justwork",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2300484,
                            type: null,
                        },
                        {
                            amount_cents: -2276012,
                            children: [],
                            debit_credit: null,
                            ledger_id: null,
                            name: "C138333 Justwork",
                            sort_code: null,
                            sub_type: null,
                            total_amount_cents: -2276012,
                            type: null,
                        },
                    ],
                    debit_credit: null,
                    ledger_id: "Hx2F4JopfKTgyiDiyPVYke",
                    name: "Salaries And Wages Payable",
                    sort_code: null,
                    sub_type: null,
                    total_amount_cents: 4829549,
                    type: null,
                },
            ],
            debit_credit: null,
            ledger_id: null,
            name: "Cash Outflows",
            sort_code: null,
            sub_type: null,
            total_amount_cents: 7158778,
            type: null,
        },
    },
    starting_cash_balance: {
        record: {
            amount_cents: 217630480,
            children: [],
            debit_credit: null,
            ledger_id: null,
            name: "Starting Cash Balance",
            sort_code: null,
            sub_type: null,
            total_amount_cents: 217630480,
            type: null,
        },
    },
};

export default MOCK_CASH_FLOW_REPORT;
