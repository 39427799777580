import * as Sentry from "@sentry/react";

const ERROR_CODES = new Set([
    "ITEM_LOGIN_REQUIRED",
    "ITEM_LOGIN_EXPIRED",
    "INVALID_CREDENTIALS",
    "INVALID_MFA",
    "ITEM_NOT_SUPPORTED", // add for Canvas Hearts debugging 11/6 may have to remove one day
    "NO_ACCOUNTS", // add for Innovations debugging 11/6 may have to remove one day
]);

const plaidErrorRequiresUserRelink = (rawPlaidError) => {
    try {
        const { message } = rawPlaidError;

        // Find the JSON part that starts after "HTTP response body: "
        const jsonStart =
            message.indexOf("HTTP response body: ") +
            "HTTP response body: ".length;
        const jsonString = message.slice(jsonStart);
        const errorObject = JSON.parse(jsonString);

        return ERROR_CODES.has(errorObject.error_code);
    } catch (error) {
        Sentry.captureException(
            new Error(`Error parsing Plaid error: ${error}`)
        );
        return false;
    }
};

export default plaidErrorRequiresUserRelink;
