import React from "react";
import PropTypes from "prop-types";
import PeriodButton from "./PeriodButton";
import PERIODS from "./constants";
import styles from "./styles.module.css";

function Periods({ period, onChange }) {
    return (
        <div className={styles.container}>
            <div className={styles.periodButtonContainer}>
                <PeriodButton
                    period={PERIODS.MONTHLY}
                    isSelected={period === PERIODS.MONTHLY}
                    onClick={onChange}
                />
            </div>
            <div className={styles.periodButtonContainer}>
                <PeriodButton
                    period={PERIODS.QUARTERLY}
                    isSelected={period === PERIODS.QUARTERLY}
                    onClick={onChange}
                />
            </div>
            <div className={styles.periodButtonContainer}>
                <PeriodButton
                    period={PERIODS.ANNUAL}
                    isSelected={period === PERIODS.ANNUAL}
                    onClick={onChange}
                />
            </div>
        </div>
    );
}

Periods.propTypes = {
    period: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Periods;
