import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function InputField({
    name,
    type,
    placeholder,
    validation,
    register,
    error,
    showError,
    className,
}) {
    return (
        <div className="flex flex-col">
            <input
                className={className || styles.input}
                type={type}
                placeholder={placeholder}
                {...register(name, validation)}
            />
            {showError && error && (
                <span className={styles.error}>{error.message}</span>
            )}
        </div>
    );
}

InputField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    validation: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired, // Register function from useForm
    error: PropTypes.object, // Accept the specific error for the field
    showError: PropTypes.bool,
    className: PropTypes.string,
};

InputField.defaultProps = {
    placeholder: "",
    error: undefined,
    showError: true,
    className: "",
};

export default InputField;
