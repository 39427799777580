import React from "react";
import PageTitle from "../../PageTitle";
import DepositBonus from "./Cards/DepositBonus";
import SpendBonus from "./Cards/SpendBonus";
import PriorityOnboarding from "./Cards/PriorityOnboarding";
import SeamlessIntegration from "./Cards/SeamlessIntegration";
import styles from "./styles.module.css";

function ExclusiveBenefits() {
    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>
                    Exclusive Benefits Only Available To Flychain Clients
                </PageTitle>
            </div>
            <div className={styles.grid}>
                <DepositBonus />
                <SpendBonus />
                <PriorityOnboarding />
                <SeamlessIntegration />
            </div>
        </div>
    );
}

export default ExclusiveBenefits;
