import dayjs from "dayjs";

const getDateRangeForAllTimeToEndDate = (endDateStr) => {
    const endDate = dayjs(endDateStr).endOf("day");
    const startDate = endDate.subtract(200, "years").startOf("day");
    return {
        startDate,
        endDate,
    };
};

export default getDateRangeForAllTimeToEndDate;
