import React from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import classNames from "classnames";
import InformationSvg from "../../../assets/Information.svg";
import RightCaretSvg from "../../../assets/RightCaret.svg";
import { getCurrencyString } from "../../../utils";
import COLORS from "../../../styles/colors";
import styles from "./styles.module.css";

const POSITION_TO_BORDER_RADIUS = {
    single: "8px",
    top: "8px 8px 0 0",
    middle: "0",
    bottom: "0 0 8px 8px",
};

function Line({
    name,
    totalAmountCents,
    ledgerId,
    numberOfIndents,
    position,
    percentage,
    percentageTooltip,
}) {
    const navigate = useNavigate();
    const isClickable = !!ledgerId;
    const marginLeft = `${34 * numberOfIndents}px`;

    const handleClick = () => {
        if (isClickable) navigate(`ledgers/${ledgerId}`);
    };

    const borderStyle = {
        borderRadius: POSITION_TO_BORDER_RADIUS[position],
        borderBottom:
            position === "top" || position === "middle" ? "none" : undefined,
    };

    const nameStyle = {
        marginLeft,
        color: isClickable ? COLORS.BLUE_PURPLE : "#000",
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.clickable]: isClickable,
            })}
            style={borderStyle}
            onClick={handleClick}
        >
            <div className={styles.nameContainer} style={nameStyle}>
                {name}
            </div>
            <div className={styles.percentageContainer}>
                {percentage !== undefined && (
                    <>
                        <div
                            className={styles.percentage}
                        >{`${percentage.toFixed(2)}%`}</div>
                        <Tooltip title={percentageTooltip}>
                            <img
                                className={styles.tooltipIcon}
                                src={InformationSvg}
                                alt="Tooltip"
                            />
                        </Tooltip>
                    </>
                )}
            </div>
            <div className={styles.amountContainer}>
                {totalAmountCents !== undefined &&
                    getCurrencyString(totalAmountCents)}
            </div>
            <div className={styles.rightCaretIconContainer}>
                {isClickable && (
                    <img
                        className={styles.rightCaretIcon}
                        src={RightCaretSvg}
                        alt="RightCaret"
                    />
                )}
            </div>
        </div>
    );
}

Line.propTypes = {
    name: PropTypes.string.isRequired,
    totalAmountCents: PropTypes.number,
    ledgerId: PropTypes.string,
    numberOfIndents: PropTypes.number.isRequired,
    position: PropTypes.oneOf(["single", "top", "middle", "bottom"]).isRequired,
    percentage: PropTypes.number,
    percentageTooltip: PropTypes.node,
};

Line.defaultProps = {
    totalAmountCents: undefined,
    ledgerId: undefined,
    percentage: undefined,
    percentageTooltip: undefined,
};

export default Line;
