import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// This is needed to parse the month year string in Safari
dayjs.extend(customParseFormat);

export const formatMonthYearStringForXAxis = (monthYearString) => {
    const date = dayjs(monthYearString, ["MMMM YYYY", "MMM YYYY"]);
    return date.format("MMM 'YY");
};

export const tickStyle = {
    fill: "black",
    fontSize: 10,
    fontWeight: 400,
};
