import { useParams } from "react-router-dom";
import { useGetDataForAdvancedPaymentOnClaimsQuery as useAdminGetDataForAdvancedPaymentOnClaimsQuery } from "../../../redux/slices/adminApi";
import { useGetDataForAdvancedPaymentOnClaimsQuery as useProviderGetDataForAdvancedPaymentOnClaimsQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetDataForAdvancedPaymentOnClaimsQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetDataForAdvancedPaymentOnClaimsQuery(providerId);
    }

    return useProviderGetDataForAdvancedPaymentOnClaimsQuery();
};

export default useGetDataForAdvancedPaymentOnClaimsQuery;
