import { useParams } from "react-router-dom";
import { useLazyDownloadBalanceSheetAsyncQuery as useAdminLazyDownloadBalanceSheetAsyncQuery } from "../../../redux/slices/adminApi";
import { useLazyDownloadBalanceSheetAsyncQuery as useProviderLazyDownloadBalanceSheetAsyncQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useLazyDownloadBalanceSheetAsyncQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const [adminTrigger, adminResult] =
        useAdminLazyDownloadBalanceSheetAsyncQuery();

    const [providerTrigger, providerResult] =
        useProviderLazyDownloadBalanceSheetAsyncQuery();

    const { providerId, businessEntityId } = useParams();

    const preloadParams = isAdminDashboard
        ? { providerId, businessEntityId }
        : { businessEntityId };

    const triggerWithParams = (params) => {
        const finalParams = { ...preloadParams, ...params };

        if (isAdminDashboard) {
            return adminTrigger(finalParams);
        }

        return providerTrigger(finalParams);
    };

    return [triggerWithParams, isAdminDashboard ? adminResult : providerResult];
};

export default useLazyDownloadBalanceSheetAsyncQuery;
