import { useParams } from "react-router-dom";
import { useGetLineForAdvancedPaymentOnClaimsQuery as useAdminGetLineForAdvancedPaymentOnClaimsQuery } from "../../../redux/slices/adminApi";
import { useGetLineForAdvancedPaymentOnClaimsQuery as useProviderGetLineForAdvancedPaymentOnClaimsQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetLineForAdvancedPaymentOnClaimsQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetLineForAdvancedPaymentOnClaimsQuery(providerId);
    }

    return useProviderGetLineForAdvancedPaymentOnClaimsQuery();
};

export default useGetLineForAdvancedPaymentOnClaimsQuery;
