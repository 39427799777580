import dayjs from "dayjs";
import QUARTERS from "../constants";
import { isDateInRange } from "../../utils";

const getStartDateForQuarter = (year, quarter) => {
    let startDate;
    switch (quarter) {
        case QUARTERS.Q1:
            startDate = dayjs(`${year}-01-01`);
            break;
        case QUARTERS.Q2:
            startDate = dayjs(`${year}-04-01`);
            break;
        case QUARTERS.Q3:
            startDate = dayjs(`${year}-07-01`);
            break;
        case QUARTERS.Q4:
            startDate = dayjs(`${year}-10-01`);
            break;
        default:
            throw new Error("Unknown quarter");
    }
    return startDate.startOf("day");
};

const getEndDateForQuarter = (year, quarter) => {
    let endDate;
    switch (quarter) {
        case QUARTERS.Q1:
            endDate = dayjs(`${year}-03-31`);
            break;
        case QUARTERS.Q2:
            endDate = dayjs(`${year}-06-30`);
            break;
        case QUARTERS.Q3:
            endDate = dayjs(`${year}-09-30`);
            break;
        case QUARTERS.Q4:
            endDate = dayjs(`${year}-12-31`);
            break;
        default:
            throw new Error("Unknown quarter");
    }
    return endDate.endOf("day");
};

const getDateRangeForQuarter = (year, quarter, maxDate) => {
    const startDate = getStartDateForQuarter(year, quarter);
    let endDate = getEndDateForQuarter(year, quarter);

    if (isDateInRange(startDate, endDate, maxDate)) {
        endDate = maxDate.endOf("day");
    }

    return {
        startDate,
        endDate,
    };
};

export default getDateRangeForQuarter;
