import dayjs from "dayjs";

const isSufficientData = (startDate, endDate) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const monthsBetween = end.diff(start, "month", true); // true for floating point precision
    return monthsBetween >= 6;
};

export default isSufficientData;
