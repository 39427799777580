import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageTitle from "../../PageTitle";
import BackIcon from "../../BackIcon";
import { selectDateRange } from "../../../redux/slices/dateRange";
import getDateRangeForAllTimeToEndDate from "./utils";
import useGetFinancialReportsValidUpUntilQuery from "../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useGetDataForLedgerLinesQuery from "../../../hooks/queriesAndMutations/useGetDataForLedgerLinesQuery";
import useGetReportType from "../../../hooks/useGetReportType";
import REPORT_TYPES from "../constants";
import MonthlyGraph from "./MonthlyGraph";
import Lines from "./Lines";
import styles from "./styles.module.css";

function LedgerLines() {
    const { ledgerId } = useParams();

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const reportType = useGetReportType();

    const dateRange =
        reportType === REPORT_TYPES.BALANCE_SHEET
            ? getDateRangeForAllTimeToEndDate(validUpUntilData.valid_up_until)
            : useSelector(selectDateRange);

    const { data: ledgerLinesData } = useGetDataForLedgerLinesQuery(
        dateRange,
        ledgerId
    );

    return ledgerLinesData ? (
        <div className={styles.container}>
            <BackIcon />
            <div className={styles.pageTitleContainer}>
                <PageTitle>{ledgerLinesData.ledger_name}</PageTitle>
            </div>
            {[
                REPORT_TYPES.REVENUE_BY_CATEGORY,
                REPORT_TYPES.EXPENSES_BY_CATEGORY,
                REPORT_TYPES.INCOME_STATEMENT,
            ].includes(reportType) && (
                <div className={styles.monthlyGraphContainer}>
                    <MonthlyGraph />
                </div>
            )}
            <Lines ledgerLinesData={ledgerLinesData} />
        </div>
    ) : null;
}

export default LedgerLines;
