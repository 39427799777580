import React from "react";
import PropTypes from "prop-types";
import DrawsTable, { drawShape } from "../DrawsTable";
import RepaymentsTable from "./RepaymentsTable";
import PageTitle from "../../../../PageTitle";
import SectionTitle from "../../../../SectionTitle";
import Modal from "../../../../Modal";
import styles from "./styles.module.css";

function DrawModal({ draw, onClose }) {
    return (
        <Modal onClose={onClose}>
            {
                // TODO: find a way to make the scrollbar always appear if container is overflowing
                // so the user knows that there are additional repayments underneath.
            }
            <div className={styles.container}>
                <div className={styles.pageTitleContainer}>
                    <PageTitle>{`Draw on ${draw.draw_funding_date}`}</PageTitle>
                </div>
                <div className={styles.sectionTitleContainer}>
                    <SectionTitle>Summary of draw</SectionTitle>
                </div>
                <div className={styles.drawsTableContainer}>
                    <DrawsTable
                        draws={[draw]}
                        includeSeeRepaymentsColumn={false}
                    />
                </div>
                <div className={styles.sectionTitleContainer}>
                    <SectionTitle>Repayments for draw</SectionTitle>
                </div>
                <div className={styles.repaymentsTableContainer}>
                    <RepaymentsTable repayments={draw.draw_payments} />
                </div>
            </div>
        </Modal>
    );
}

DrawModal.propTypes = {
    draw: drawShape.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default DrawModal;
