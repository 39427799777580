import { useParams } from "react-router-dom";
import { useGetValuationQuery as useAdminGetValuationQuery } from "../../../redux/slices/adminApi";
import { useGetValuationQuery as useProviderGetValuationQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetValuationQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetValuationQuery(providerId);
    }

    return useProviderGetValuationQuery();
};

export default useGetValuationQuery;
