/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import InstitutionHeader from "../InstitutionHeader";
import ConnectionBroken from "../ConnectionBroken";
import AccountsConnected from "../AccountsConnected";
import PlaidLinkButton from "../../PlaidLinkButton";
import styles from "./styles.module.css";

const getPlaidItemStatusAndCallToAction = (plaidItem) => {
    const {
        is_admin_linked,
        internal_plaid_item_id,
        raw_plaid_error,
        accounts,
    } = plaidItem;

    // Plaid item is fine so there's nothing to do.
    if (!raw_plaid_error) {
        return {
            plaidItemStatus: (
                <AccountsConnected numberOfAccounts={accounts.length} />
            ),
            callToAction: null,
        };
    }

    return {
        plaidItemStatus: <ConnectionBroken />,
        callToAction: (
            <div>
                <div
                    className={styles.adminMessageContainer}
                >{`Plaid item with id = ${internal_plaid_item_id} is broken due to \n\n ${raw_plaid_error.message}\n\n Admin should investigate.`}</div>
                {is_admin_linked && (
                    <PlaidLinkButton
                        plaidItemId={internal_plaid_item_id}
                        color="secondary"
                    />
                )}
            </div>
        ),
    };
};

function AdminPlaidItemHeader({ plaidItem }) {
    const { is_admin_linked } = plaidItem;

    const { plaidItemStatus, callToAction } =
        getPlaidItemStatusAndCallToAction(plaidItem);

    return (
        <div className={styles.container}>
            <div className={styles.adminLinkedContainer}>
                Admin Linked:{" "}
                {is_admin_linked ? (
                    <span className={styles.green}>TRUE</span>
                ) : (
                    <span>FALSE</span>
                )}
            </div>
            <InstitutionHeader
                plaidItem={plaidItem}
                plaidItemStatus={plaidItemStatus}
            />
            <div className={styles.callToActionContainer}>{callToAction}</div>
        </div>
    );
}

AdminPlaidItemHeader.propTypes = {
    plaidItem: PropTypes.object.isRequired,
};

export default AdminPlaidItemHeader;
