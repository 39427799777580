import React from "react";
import useGetProviderQuery from "../../../../hooks/queriesAndMutations/useGetProviderQuery";
import PageTitle from "../../../PageTitle";
import styles from "./styles.module.css";

function SelectedProvider() {
    const { data: provider } = useGetProviderQuery();
    return provider ? (
        <div className={styles.container}>
            <div className={styles.label}>Currently Selected Provider</div>
            <PageTitle>{provider.legal_name}</PageTitle>
        </div>
    ) : null;
}

export default SelectedProvider;
