import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "../DatePicker";
import ShortcutsButton from "./ShortcutsButton";
import styles from "./styles.module.css";

function CustomDateRangePicker({ dateRange, onChange, maxDate }) {
    const [currentDateRange, setCurrentDateRange] = useState({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    });

    const updateDateRange = (newDateRange) => {
        setCurrentDateRange(newDateRange);
        onChange(newDateRange);
    };

    const handleStartDateChange = (newStartDate) => {
        if (newStartDate.isAfter(currentDateRange.endDate)) {
            updateDateRange({ startDate: newStartDate, endDate: newStartDate });
        } else {
            updateDateRange({
                startDate: newStartDate,
                endDate: currentDateRange.endDate,
            });
        }
    };

    const handleEndDateChange = (newEndDate) => {
        if (newEndDate.isBefore(currentDateRange.startDate)) {
            updateDateRange({ startDate: newEndDate, endDate: newEndDate });
        } else {
            updateDateRange({
                startDate: currentDateRange.startDate,
                endDate: newEndDate,
            });
        }
    };

    return (
        <div className={styles.container}>
            <DatePicker
                date={currentDateRange.startDate}
                onChange={handleStartDateChange}
                maxDate={maxDate}
            />
            <span className={styles.separator}>-</span>
            <DatePicker
                date={currentDateRange.endDate}
                onChange={handleEndDateChange}
                maxDate={maxDate}
            />
            <div className={styles.shortcutsButtonContainer}>
                <ShortcutsButton
                    dateRange={dateRange}
                    onChange={updateDateRange}
                    maxDate={maxDate}
                />
            </div>
        </div>
    );
}

CustomDateRangePicker.propTypes = {
    dateRange: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default CustomDateRangePicker;
