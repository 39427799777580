export const financialReportsSubtabs = [
    {
        label: "Income Statement",
        path: "/incomeStatement",
    },
    {
        label: "Cash Flow",
        path: "/cashFlow",
    },
    {
        label: "Balance Sheet",
        path: "/balanceSheet",
    },
    {
        label: "Revenue",
        path: "/revenue",
    },
    {
        label: "Expenses",
        path: "/expenses",
    },
];

export const capitalSubtabs = [
    {
        label: "Advanced Claims",
        path: "/advancedPaymentOnClaims",
    },
    {
        label: "Line Of Credit",
        path: "/lineOfCredit",
    },
    {
        label: "Term Loan",
        path: "/termLoan",
    },
    {
        label: "SBA",
        path: "/sbaPrograms",
    },
];

export const bankingSubtabs = [
    {
        label: "Treasury",
        path: "/treasury",
    },
];

export const onboardingFlowSubtabs = [
    {
        label: "Entities",
        path: "/entities",
    },
    {
        label: "Mappings",
        path: "/mappings",
    },
    {
        label: "Linking",
        path: "/linking",
    },
    {
        label: "Check",
        path: "/check",
    },
];
