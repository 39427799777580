import React from "react";
import Card from "../../Card";
import SeamlessIntegrationSvg from "./SeamlessIntegration.svg";

function SeamlessIntegration() {
    return (
        <Card
            imageSrc={SeamlessIntegrationSvg}
            title="Seamless Integration With Flychain"
            body="Mercury has a native integration with the Flychain platform enabling a seamless exchange of data."
        />
    );
}

export default SeamlessIntegration;
