import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

function Card({ imageSrc, title, body }) {
    return (
        <div className={styles.container}>
            <img src={imageSrc} alt={title} className={styles.image} />
            <div className={styles.title}>{title}</div>
            <div className={styles.body}>{body}</div>
        </div>
    );
}

Card.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    body: PropTypes.string.isRequired,
};

export default Card;
