import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import getDateRangeForMonth from "./utils";
import styles from "./styles.module.css";

function MonthButton({ year, month, onClick, maxDate }) {
    const dateRange = getDateRangeForMonth(year, month, maxDate);

    const disabled = dateRange.startDate.isAfter(maxDate, "day");

    const handleClick = () => {
        if (!disabled) {
            onClick(dateRange);
        }
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.disabled]: disabled,
            })}
            onClick={handleClick}
        >
            {dateRange.startDate.format("MMM")}
        </div>
    );
}

MonthButton.propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default MonthButton;
