import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import LabeledText from "../../../../LabeledText";
import styles from "./styles.module.css";

function Summary({ data }) {
    const startingCashBalance = data.starting_cash_balance.record;
    const endingCashBalance = data.ending_cash_balance.record;
    const netCashFlow = data.net_cash_flow.record;
    return (
        <div className={styles.container}>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label={startingCashBalance.name}
                    text={getCurrencyString(
                        startingCashBalance.total_amount_cents
                    )}
                />
            </div>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label={endingCashBalance.name}
                    text={getCurrencyString(
                        endingCashBalance.total_amount_cents
                    )}
                />
            </div>
            <div className={styles.labeledTextContainer}>
                <LabeledText
                    label={netCashFlow.name}
                    text={getCurrencyString(netCashFlow.total_amount_cents)}
                />
            </div>
        </div>
    );
}

Summary.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Summary;
