import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./styles.module.css";

function PeriodButton({ period, isSelected, onClick }) {
    const handleClick = () => {
        onClick(period);
    };
    return (
        <div
            className={classNames(styles.container, {
                [styles.selected]: isSelected,
            })}
            onClick={handleClick}
        >
            {period}
        </div>
    );
}

PeriodButton.propTypes = {
    period: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default PeriodButton;
