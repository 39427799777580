import React from "react";
import { useForm } from "react-hook-form";
import InputField from "../../../InputField";
import Button from "../../../Button";
import useGetMercurySignupLinkMutation from "../../../../hooks/queriesAndMutations/useGetMercurySignupLinkMutation";
import styles from "./styles.module.css";

function Signup() {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
    });

    const [getMercurySignupLink] = useGetMercurySignupLinkMutation();

    const handleFormSubmit = async (data) => {
        try {
            const response = await getMercurySignupLink(data.email);
            window.open(response.data.signup_link, "_blank");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputFieldContainer}>
                <InputField
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    validation={{
                        required: "Email is required",
                        pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address",
                        },
                    }}
                    register={register}
                    error={errors.email}
                    showError={false}
                />
            </div>
            <Button
                onClick={handleSubmit(handleFormSubmit)}
                disabled={!isValid}
            >
                Sign Up
            </Button>
        </div>
    );
}

export default Signup;
