/* eslint-disable camelcase */
import React, { useState } from "react";
import PageTitle from "../PageTitle";
import SavingsCall from "./SavingsCall";
import SavingsCards from "./SavingsCards";
import RealizedSavings from "./RealizedSavings";
import CalendlyModal from "../CalendlyModal";
import { CALENDLY_URLS } from "../CalendlyModal/constants";
import useGetSavingsQuery from "../../hooks/queriesAndMutations/useGetSavingsQuery";
import styles from "./styles.module.css";

function Savings() {
    const { data: savingsData } = useGetSavingsQuery();

    const [showSavingsCallModal, setShowSavingsCallModal] = useState(false);

    const onClick = () => {
        setShowSavingsCallModal(true);
    };

    const onClose = () => {
        setShowSavingsCallModal(false);
    };

    return savingsData ? (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Savings</PageTitle>
            </div>
            <div className={styles.realizedSavingsContainer}>
                <RealizedSavings data={savingsData} />
            </div>
            <div className={styles.savingsCallContainer}>
                <SavingsCall onClick={onClick} />
            </div>
            <div className={styles.savingsCardsContainer}>
                <SavingsCards />
            </div>
            {showSavingsCallModal && (
                <CalendlyModal
                    onClose={onClose}
                    calendlyUrl={CALENDLY_URLS.SAVINGS_CALL}
                />
            )}
        </div>
    ) : null;
}

export default Savings;
