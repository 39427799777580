import React from "react";
import Card from "../../Card";
import PriorityOnboardingSvg from "./PriorityOnboarding.svg";

function PriorityOnboarding() {
    return (
        <Card
            imageSrc={PriorityOnboardingSvg}
            title="Priority Onboarding and Customer Support"
            body="Mercury will help you migrate your payments, payroll, and other services from your previous bank to Mercury."
        />
    );
}

export default PriorityOnboarding;
