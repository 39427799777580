import React from "react";
import SectionTitle from "../../SectionTitle";
import BusinessEntitySelect from "../BusinessEntitySelect";
import ValidUpUntil from "../ValidUpUntil";
import NetProfitGraph from "./NetProfitGraph";
import Report from "./Report";
import styles from "./styles.module.css";

function IncomeStatement() {
    return (
        <div className={styles.container}>
            <BusinessEntitySelect />
            <div className={styles.validUpUntilContainer}>
                <ValidUpUntil />
            </div>
            <div className={styles.sectionTitleContainer}>
                <SectionTitle>Net Profit by Month</SectionTitle>
            </div>
            <div className={styles.netProfitGraphContainer}>
                <NetProfitGraph />
            </div>
            <Report />
        </div>
    );
}

export default IncomeStatement;
