import useGetPartnerQuery from "../queriesAndMutations/useGetPartnerQuery";
import { capitalSubtabs } from "../../components/SideBar/constants";
import PARTNERS from "../../partners/constants";

const useGetCapitalSubtabs = () => {
    const { data: partner } = useGetPartnerQuery();

    if (!partner) return undefined;

    if (partner.internal_name === PARTNERS.HEADSTART) {
        return capitalSubtabs.filter(
            (subtab) => subtab.path !== "/lineOfCredit"
        );
    }

    return capitalSubtabs;
};

export default useGetCapitalSubtabs;
