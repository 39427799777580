import React from "react";
import Card from "../../Card";
import SpendBonusSvg from "./SpendBonus.svg";

function SpendBonus() {
    return (
        <Card
            imageSrc={SpendBonusSvg}
            title="Additional $500 Cash Bonus"
            body="Receive an additional $500 cash bonus after spending $10K on the Mercury IO credit card within 90 days of receiving the card."
        />
    );
}

export default SpendBonus;
