import React, { useState } from "react";
import DownloadSvg from "../../../assets/Download.svg";
import Modal from "./Modal";
import AsyncModal from "./AsyncModal";
import useIsAdminDashboard from "../../../hooks/useIsAdminDashboard";
import styles from "./styles.module.css";

// TODO: remove this and just display Modal inline once you've fully tested AsyncModal
const displayModal = (onClose, isAdminDashboard) => {
    if (isAdminDashboard) {
        return <AsyncModal onClose={onClose} />;
    }
    return <Modal onClose={onClose} />;
};

function ExportReportButton() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onClose = () => {
        setIsModalOpen(false);
    };

    const onClick = () => {
        setIsModalOpen(true);
    };

    const isAdminDashboard = useIsAdminDashboard();

    return (
        <div>
            <img
                src={DownloadSvg}
                alt="Download"
                onClick={onClick}
                className={styles.exportReportButton}
            />
            {isModalOpen && displayModal(onClose, isAdminDashboard)}
        </div>
    );
}

export default ExportReportButton;
