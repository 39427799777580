import React, { useState } from "react";
import PropTypes from "prop-types";
import useClickOutside from "../../../hooks/useClickOutside";
import Dropdown from "./Dropdown";
import Button from "../../Button";
import styles from "./styles.module.css";

function ShortcutsButton({ dateRange, onChange, maxDate }) {
    const [showDropdown, setShowDropdown] = useState(false);

    const onClickOutside = () => {
        setShowDropdown(false);
    };

    const containerRef = useClickOutside(onClickOutside);

    const onClick = () => {
        setShowDropdown(!showDropdown);
    };

    const onDateRangeChange = (newDateRange) => {
        onChange(newDateRange);
        setShowDropdown(false);
    };

    return (
        <div ref={containerRef} className={styles.container}>
            <Button color="primary" onClick={onClick}>
                Shortcuts
            </Button>
            {showDropdown && (
                <Dropdown
                    dateRange={dateRange}
                    onChange={onDateRangeChange}
                    maxDate={maxDate}
                />
            )}
        </div>
    );
}

ShortcutsButton.propTypes = {
    dateRange: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    maxDate: PropTypes.object.isRequired,
};

export default ShortcutsButton;
