import React from "react";
import PropTypes from "prop-types";
import Line from "../Line";

const renderGrandchild = (grandchild) => {
    const key = grandchild.ledger_id || grandchild.name;

    return (
        <Line
            key={key}
            ledgerId={grandchild.ledger_id || undefined}
            name={grandchild.name}
            totalAmountCents={grandchild.total_amount_cents}
            numberOfIndents={2}
            position="middle"
        />
    );
};

const renderChild = (child) => {
    const key = child.ledger_id || child.name;

    if (child.children.length === 0) {
        return (
            <Line
                key={key}
                ledgerId={child.ledger_id || undefined}
                name={child.name}
                totalAmountCents={child.total_amount_cents}
                numberOfIndents={1}
                position="middle"
            />
        );
    }

    return (
        <div key={key}>
            <Line name={child.name} numberOfIndents={1} position="middle" />
            {child.children.map((grandchild) => renderGrandchild(grandchild))}
            <Line
                name={`${child.name} Total`}
                totalAmountCents={child.total_amount_cents}
                numberOfIndents={1}
                position="middle"
            />
        </div>
    );
};

function Section({
    name,
    totalAmountCents,
    children,
    percentage,
    percentageTooltip,
}) {
    if (children.length === 0) {
        return (
            <Line
                name={name}
                totalAmountCents={totalAmountCents}
                numberOfIndents={0}
                position="single"
                percentage={percentage}
                percentageTooltip={percentageTooltip}
            />
        );
    }

    return (
        <div>
            <Line name={name} numberOfIndents={0} position="top" />
            {children.map((child) => renderChild(child))}
            <Line
                name={`${name} Total`}
                totalAmountCents={totalAmountCents}
                numberOfIndents={0}
                position="bottom"
            />
        </div>
    );
}

Section.propTypes = {
    name: PropTypes.string.isRequired,
    totalAmountCents: PropTypes.number.isRequired,
    children: PropTypes.arrayOf(PropTypes.object.isRequired),
    percentage: PropTypes.number,
    percentageTooltip: PropTypes.node,
};

Section.defaultProps = {
    children: [],
    percentage: undefined,
    percentageTooltip: undefined,
};

export default Section;
