import { useParams } from "react-router-dom";
import { useGetBalanceSheetQuery as useAdminGetBalanceSheetQuery } from "../../../redux/slices/adminApi";
import { useGetBalanceSheetQuery as useProviderGetBalanceSheetQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetBalanceSheetQuery = (endDate) => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const endDateStr = endDate.format("YYYY-MM-DD");

    if (isAdminDashboard) {
        return useAdminGetBalanceSheetQuery({
            providerId,
            businessEntityId,
            endDate: endDateStr,
        });
    }

    return useProviderGetBalanceSheetQuery({
        businessEntityId,
        endDate: endDateStr,
    });
};

export default useGetBalanceSheetQuery;
