import React from "react";
import { useNavigate } from "react-router-dom";
import useGetPartnerQuery from "../../../hooks/queriesAndMutations/useGetPartnerQuery";
import PageTitle from "../../PageTitle";
import CapitalProduct from "./CapitalProduct";
import PARTNERS from "../../../partners/constants";
import styles from "./styles.module.css";

function CapitalProducts() {
    const { data: partner } = useGetPartnerQuery();

    const navigate = useNavigate();

    const onClickForAdvancedPaymentOnClaims = () => {
        navigate("advancedPaymentOnClaims");
    };

    const onClickForLineOfCredit = () => {
        navigate("lineOfCredit");
    };

    const onClickForTermLoan = () => {
        navigate("termLoan");
    };

    const onClickForSBAPrograms = () => {
        navigate("sbaPrograms");
    };

    const advancedPaymentOnClaims = (
        <CapitalProduct
            title="Advanced Payment on Claims"
            body="Enroll in our Advanced Payment on Claims program to bring forward your insurance reimbursements so you don't have to wait 1-4 months to get paid. Almost all providers qualify and we can fund within 2 business days."
            onClick={onClickForAdvancedPaymentOnClaims}
        />
    );

    const lineOfCredit = (
        <CapitalProduct
            title="Line of Credit"
            body="Secure a line of credit for a rainy day. Draw down on your line whenever you need funding: no origination fees, no unused line fees, no prepayment penalties!"
            onClick={onClickForLineOfCredit}
        />
    );

    const termLoan = (
        <CapitalProduct
            title="Term Loan"
            body="Get a term loan to fund your next big growth initiative. We offer term loans with repayment periods of 6 months to 2 years and we can fund within 3 business days."
            onClick={onClickForTermLoan}
        />
    );

    const sbaPrograms = (
        <CapitalProduct
            title="SBA Programs"
            body="Apply for an SBA loan to get access to some of the most favorable rates in the country! In addition, the SBA Express program can get you funded within 5 business days."
            onClick={onClickForSBAPrograms}
        />
    );

    const acquisitionFinancing = (
        <CapitalProduct
            title="Acquisition Financing"
            body="Expand your practice to new locations and states by leveraging our acquisition financing. Our program is purpose-built for healthcare and is intended to help take your practice to the next level."
            comingSoon
        />
    );

    if (!partner) return null;

    return (
        <div className={styles.container}>
            <div className={styles.pageTitleContainer}>
                <PageTitle>Our Capital Products</PageTitle>
            </div>
            {partner.internal_name === PARTNERS.HEADSTART ? (
                <>
                    <div className={styles.row}>
                        <div className={styles.capitalProductContainer}>
                            {advancedPaymentOnClaims}
                        </div>
                        {termLoan}
                    </div>
                    <div className={styles.row}>
                        <div className={styles.capitalProductContainer}>
                            {sbaPrograms}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.row}>
                        <div className={styles.capitalProductContainer}>
                            {advancedPaymentOnClaims}
                        </div>
                        {lineOfCredit}
                    </div>
                    <div className={styles.row}>
                        <div className={styles.capitalProductContainer}>
                            {termLoan}
                        </div>
                        {sbaPrograms}
                    </div>
                    <div className={styles.row}>{acquisitionFinancing}</div>
                </>
            )}
        </div>
    );
}

export default CapitalProducts;
