import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../utils";
import styles from "./styles.module.css";

function GraphTooltip({ active, payload }) {
    if (active && payload.length > 0) {
        const { name: ledgerId, value, color, payload: dataPoint } = payload[0];
        const { month } = dataPoint;
        const proportion = dataPoint[`${ledgerId} Proportion`];
        const ledgerName = dataPoint[`${ledgerId} Name`];
        return (
            <div className={styles.container}>
                <div className={styles.month}>{month}</div>
                <div className={styles.ledger} style={{ color }}>
                    {ledgerName}
                </div>
                <div className={styles.numbersContainer}>
                    <span className={styles.amount}>
                        {getCurrencyString(value)}
                    </span>
                    <span>{`${proportion.toFixed(2)}%`}</span>
                </div>
            </div>
        );
    }
    return null;
}

GraphTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(
        PropTypes.shape({
            payload: PropTypes.shape({
                month: PropTypes.string.isRequired,
            }).isRequired,
            name: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            color: PropTypes.string.isRequired,
        }).isRequired
    ),
};

GraphTooltip.defaultProps = {
    active: false,
    payload: [],
};

export default GraphTooltip;
