import React from "react";
import Card from "../../../Card";
import Button from "../../../Button";
import styles from "./styles.module.css";

function FreeValuation() {
    const onClick = () => {
        // TODO: replace this with a Calendly link once Michael creates it.
        window.open("https://calendar.app.google/DktvgyNeuoQnBGMW8", "_blank");
    };
    return (
        <Card showTab={false}>
            <div className={styles.container}>
                <div className={styles.title}>
                    Get A Full Valuation For Free
                </div>
                <div className={styles.body}>
                    The Valuation Range that we calculate is an estimate of your
                    business&apos;s valuation. For a comprehensive valuation,
                    please schedule a free valuation below with our trusted M&A
                    partner.
                </div>
                <Button onClick={onClick}>Schedule a Free Valuation</Button>
            </div>
        </Card>
    );
}

export default FreeValuation;
