export const transformGetOrCreateUserResponse = (data) => {
    const { user } = data;
    return user;
};

export const transformGetOfferResponse = (data) => {
    const { offer } = data;
    return offer;
};

export const getPlaidLinkTokenUrl = ({ theme, plaidItemId }) => {
    if (!plaidItemId) {
        return `/v1/plaid/link?theme=${theme}`;
    }
    return `/v1/plaid/link?theme=${theme}&plaid_item_id=${plaidItemId}`;
};

export const getTopExpensesGraphUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/top_expenses?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getFinancialReportsSummaryUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/summary?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getBalanceSheetUrl = ({ businessEntityId, endDate }) =>
    `/v1/platform/financial_reports/balance_sheet_v2?business_entity_id=${businessEntityId}&end_date=${endDate}`;

export const getCashFlowReportUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/cash_flow_report_v2?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getLedgerLinesUrl = ({
    businessEntityId,
    startDate,
    endDate,
    ledgerId,
    isCashFlow,
}) =>
    `/v1/platform/financial_reports/ledger_lines?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&ledger_id=${ledgerId}&is_cash_flow=${isCashFlow}`;

export const getDownloadIncomeStatementUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/income_statement/download?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getDownloadIncomeStatementAsyncUrl = ({
    businessEntityId,
    startDate,
    endDate,
    email,
}) =>
    `/v1/platform/financial_reports/income_statement/download/async?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getDownloadBalanceSheetUrl = ({ businessEntityId, endDate }) =>
    `/v1/platform/financial_reports/balance_sheet/download?business_entity_id=${businessEntityId}&end_date=${endDate}`;

export const getDownloadBalanceSheetAsyncUrl = ({
    businessEntityId,
    endDate,
    email,
}) =>
    `/v1/platform/financial_reports/balance_sheet/download/async?business_entity_id=${businessEntityId}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getDownloadCashFlowReportUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/cash_flow_report/download?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getDownloadCashFlowReportAsyncUrl = ({
    businessEntityId,
    startDate,
    endDate,
    email,
}) =>
    `/v1/platform/financial_reports/cash_flow_report/download/async?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getDownloadExpensesByCategoryUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/expenses_by_category/download?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getDownloadExpensesByCategoryAsyncUrl = ({
    businessEntityId,
    startDate,
    endDate,
    email,
}) =>
    `/v1/platform/financial_reports/expenses_by_category/download/async?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}&email=${encodeURIComponent(
        email
    )}`;

export const getRevenueByCategoryUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/revenue_by_category?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;

export const getMonthlyLedgerTotalsUrl = ({ businessEntityId, ledgerId }) =>
    `/v1/platform/financial_reports/monthly_ledger_totals?business_entity_id=${businessEntityId}&ledger_id=${ledgerId}`;

export const getIncomeStatementUrl = ({
    businessEntityId,
    startDate,
    endDate,
}) =>
    `/v1/platform/financial_reports/income_statement_v2?business_entity_id=${businessEntityId}&start_date=${startDate}&end_date=${endDate}`;
