import React from "react";
import HeaderSection from "../HeaderSection";
import ProductSummary, { productSummaryTitles } from "../ProductSummary";
import QualificationRequirements, {
    qualificationRequirementsTitles,
} from "../QualificationRequirements";
import LineOfCreditGraphic from "../../../assets/LineOfCreditGraphic.svg";
import styles from "./styles.module.css";

const productSummaryRows = [
    [
        productSummaryTitles.USE_OF_FUNDS,
        "Funds can be used for any business purpose",
    ],
    [productSummaryTitles.LOAN_AMOUNT, "$1,000 to $1,000,000"],
    [productSummaryTitles.TIME_TO_FUND, "Funding as soon as 5 business days"],
    [productSummaryTitles.REPAYMENT_PERIOD, "10 months"],
    [
        productSummaryTitles.INTEREST_RATE,
        "16-25% annualized interest rate depending on your credit profile",
    ],
];

const qualificationRequirementsRows = [
    [qualificationRequirementsTitles.TIME_IN_BUSINESS, "At least 6 months"],
    [qualificationRequirementsTitles.FICO, "At least 600"],
];

function LineOfCredit() {
    const onClick = () => {
        window.open("https://flychain.direct.vaya.finance/login", "_blank");
    };
    return (
        <div className={styles.container}>
            <HeaderSection
                title="Line of Credit"
                body="Establish a line of credit today that you can instantly draw down on whenever you need funding. We don't charge any fees unless you use the line: no origination fees, no unused line fees, and no prepayment penalties. We believe you should establish a line of credit as a backup measure if you don't already have one."
                buttonText="Apply Now"
                onClick={onClick}
                graphic={
                    <img
                        className={styles.graphic}
                        src={LineOfCreditGraphic}
                        alt="LineOfCreditGraphicSvg"
                    />
                }
            />
            <div className={styles.pageContainer}>
                <div className={styles.tableContainer}>
                    <ProductSummary rows={productSummaryRows} />
                </div>
                <QualificationRequirements
                    rows={qualificationRequirementsRows}
                />
            </div>
        </div>
    );
}

export default LineOfCredit;
