import React from "react";
import PropTypes from "prop-types";
import Table from "../../../Table";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import { getCurrencyString } from "../../../../utils";

function NetProfitTable({ rawResults }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery();

    const businessEntityIdToNetProfitMap = rawResults.reduce((acc, item) => {
        acc[item.business_entity_id] = item.net_profit_cents;
        return acc;
    }, {});

    const headers = ["Business Entity", "Net Profit"];
    const rows = businessEntities.map((businessEntity) => [
        businessEntity.display_name || businessEntity.legal_name,
        getCurrencyString(businessEntityIdToNetProfitMap[businessEntity.id]),
    ]);

    return <Table headers={headers} rows={rows} />;
}

NetProfitTable.propTypes = {
    rawResults: PropTypes.arrayOf(
        PropTypes.shape({
            business_entity_id: PropTypes.string.isRequired,
            net_profit_cents: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
};

export default NetProfitTable;
