/* eslint-disable camelcase */
import React from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import Card from "../../../Card";
import useGetValuationQuery from "../../../../hooks/queriesAndMutations/useGetValuationQuery";
import { getCurrencyString } from "../../../../utils";
import isSufficientData from "./utils";
import styles from "./styles.module.css";

const displayContext = (
    isDataSufficient,
    startDate,
    endDate,
    isNegativeEbitda
) => {
    if (!isDataSufficient) {
        return (
            <div className={styles.context}>
                {`We need at least six months of financial data on your practice to calculate a Valuation Range - we currently have data for ${dayjs(
                    startDate
                ).format("MMMM D, YYYY")} to ${dayjs(endDate).format(
                    "MMMM D, YYYY"
                )}. With less than six months of data, factors such as seasonality and one-time expenses can skew results. For a full
                valuation, we recommend scheduling a free valuation with our M&A
                partner.`}
            </div>
        );
    }

    if (isNegativeEbitda) {
        return (
            <div className={styles.context}>
                Your annualized EBITDA is negative and therefore we aren&apos;t
                able to provide a Valuation Range at this time. For a full
                valuation, we recommend scheduling a free valuation with our M&A
                partner. To see how we calculated your annualized EBITDA, please
                refer to the section below.
            </div>
        );
    }

    return (
        <div className={styles.context}>
            The Valuation Range provided above is intended for informational
            purposes only and should not be considered as financial, investment,
            or legal advice. Flychain assumes no responsibility for any
            decisions made based on this valuation and disclaims all liability
            in connection with its use.
        </div>
    );
};

function ValuationRange() {
    const { data: valuationData } = useGetValuationQuery();

    if (!valuationData) return null;

    const { annualized_ebitda_cents, valuation_cents, start_date, end_date } =
        valuationData;

    const isDataSufficient = isSufficientData(start_date, end_date);

    const isNegativeEbitda = annualized_ebitda_cents < 0;

    const shouldBlurValuationRange = !isDataSufficient || isNegativeEbitda;

    return (
        <Card showTab={false}>
            <div className={styles.container}>
                <div className={styles.title}>Valuation Range</div>
                <div
                    className={classNames(styles.valuationRange, {
                        [styles.blurred]: shouldBlurValuationRange,
                    })}
                >
                    <div>
                        <span>{getCurrencyString(valuation_cents[0])}</span>
                        <span className={styles.to}> to </span>
                    </div>
                    <div>{getCurrencyString(valuation_cents[1])}</div>
                </div>
                {displayContext(
                    isDataSufficient,
                    start_date,
                    end_date,
                    isNegativeEbitda
                )}
            </div>
        </Card>
    );
}

export default ValuationRange;
