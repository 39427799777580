import React, { useState } from "react";
import HeaderSection from "../../HeaderSection";
import ARGraphicSvg from "../../../../assets/ARGraphic.svg";
import ProductSummary, { productSummaryTitles } from "../../ProductSummary";
import QualificationRequirements, {
    qualificationRequirementsTitles,
} from "../../QualificationRequirements";
import CalendlyModal from "../../../CalendlyModal";
import { CALENDLY_URLS } from "../../../CalendlyModal/constants";
import styles from "./styles.module.css";

const productSummaryRows = [
    [
        productSummaryTitles.USE_OF_FUNDS,
        "Funds can be used for any business purpose",
    ],
    [productSummaryTitles.LOAN_AMOUNT, "$1,000 to $1,000,000"],
    [productSummaryTitles.TIME_TO_FUND, "Funding within 2 business days"],
    [productSummaryTitles.REPAYMENT_PERIOD, "1-4 months"],
    [
        productSummaryTitles.INTEREST_RATE,
        "1.3% to 2% per 30 days depending on your credit profile",
    ],
];

const qualificationRequirementsRows = [
    [qualificationRequirementsTitles.TIME_IN_BUSINESS, "At least 3 months"],
    [qualificationRequirementsTitles.FICO, "At least 500"],
    [
        qualificationRequirementsTitles.CLAIMS,
        "At least 3 months of claims data",
    ],
];

function Landing() {
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    return (
        <div className={styles.container}>
            <HeaderSection
                title="Advanced Payment on Claims"
                body="Receive a lump-sum advance on your outstanding claims to meet your cash flow needs. Almost all our providers qualify and we fund within two business days."
                buttonText="Get Started"
                onClick={() => setShowCalendlyModal(true)}
                graphic={
                    <img
                        className={styles.graphic}
                        src={ARGraphicSvg}
                        alt="ARGraphicSvg"
                    />
                }
            />
            <div className={styles.pageContainer}>
                <div className={styles.tableContainer}>
                    <ProductSummary rows={productSummaryRows} />
                </div>
                <QualificationRequirements
                    rows={qualificationRequirementsRows}
                />
            </div>
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.FLYCHAIN_TEAM}
                />
            )}
        </div>
    );
}

export default Landing;
