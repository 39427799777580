import { useParams } from "react-router-dom";
import { useGetSavingsQuery as useAdminGetSavingsQuery } from "../../../redux/slices/adminApi";
import { useGetSavingsQuery as useProviderGetSavingsQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetSavingsQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId } = useParams();

    if (isAdminDashboard) {
        return useAdminGetSavingsQuery(providerId);
    }

    return useProviderGetSavingsQuery();
};

export default useGetSavingsQuery;
