import React from "react";
import Card from "../../Card";
import styles from "./styles.module.css";

function SavingsCards() {
    return (
        <div>
            <div className={styles.savingsCardsTitle}>
                We help our providers realize multiple savings opportunities
                including:
            </div>
            <div className={styles.row}>
                <Card showTab tabText="Health Insurance" tabColor="green">
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Reduce Health Insurance Premiums
                        </div>
                        <div className={styles.body}>
                            Health insurance is more expensive than ever while
                            also being more important than ever in recruiting
                            and retaining employees. Our insurance experts do a
                            side by side analysis to make sure you are offering
                            the best health plans to your employees at the
                            lowest possible cost.
                        </div>
                    </div>
                </Card>
                <Card showTab tabText="Tax Credits" tabColor="blue">
                    <div className={styles.content}>
                        <div className={styles.title}>Redeem Tax Credits</div>
                        <div className={styles.body}>
                            Our team of CPAs and tax professionals offer
                            complimentary tax planning meetings to help reduce
                            your tax liability and redeem valuable tax credits
                            such as R&D, ERC, and fixed assets.
                        </div>
                    </div>
                </Card>
                <Card showTab tabText="Liability Insurance" tabColor="green">
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Reduce Liability Insurance Premiums
                        </div>
                        <div className={styles.body}>
                            Medical malpractice, professional, and general
                            liability insurance can be major costs for your
                            practice. Our insurance experts do a side by side
                            analysis to make sure you have the best coverage at
                            the lowest possible cost.
                        </div>
                    </div>
                </Card>
            </div>
            <div className={styles.row}>
                <Card showTab tabText="Business Grants" tabColor="purple">
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Qualify for Business Grants
                        </div>
                        <div className={styles.body}>
                            Most states offer support for businesses in the form
                            of grants and other financial incentives to
                            encourage business growth. Our team of experts will
                            help you identify which grants you qualify for and
                            help you fill out the requisite applications.
                        </div>
                    </div>
                </Card>
                <Card showTab tabText="Financing" tabColor="yellow">
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Use Our Line of Credit
                        </div>
                        <div className={styles.body}>
                            Unlike other lenders, we don&apos;t charge
                            origination fees, broker fees, or pre-payment
                            penalties when you use our line of credit. Whenever
                            you need capital, draw down on our line of credit
                            and instantly save money on fees.
                        </div>
                    </div>
                </Card>
                <Card showTab tabText="Treasury" tabColor="blue-purple">
                    <div className={styles.content}>
                        <div className={styles.title}>
                            Open a High-Yield Treasury Account
                        </div>
                        <div className={styles.body}>
                            Open a high-yield treasury account with our trusted
                            brokerage partner. Put your operating capital and
                            savings to work and earn an annual risk-free return
                            of 5%+.
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default SavingsCards;
