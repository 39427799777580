import React from "react";
import PropTypes from "prop-types";
import CloseSvg from "../../assets/Close.svg";
import styles from "./styles.module.css";

function Modal({ onClose, children }) {
    return (
        <div className={styles.container}>
            <div className="w-1/5" />
            <div className="flex flex-row justify-center items-center p-[20px] w-4/5">
                <div className="flex flex-col bg-white rounded-lg p-[20px] ">
                    <div className="flex flex-row justify-end">
                        <img
                            className="cursor-pointer h-[15px]"
                            src={CloseSvg}
                            alt="Close"
                            onClick={onClose}
                        />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default Modal;
