import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectDateRange,
    serializeDateRange,
} from "../../../redux/slices/dateRange";
import { useGetIncomeStatementQuery as useAdminGetIncomeStatementQuery } from "../../../redux/slices/adminApi";
import { useGetIncomeStatementQuery as useProviderGetIncomeStatementQuery } from "../../../redux/slices/api";
import useIsAdminDashboard from "../../useIsAdminDashboard";

const useGetIncomeStatementQuery = () => {
    const isAdminDashboard = useIsAdminDashboard();

    const { providerId, businessEntityId } = useParams();

    const dateRange = useSelector(selectDateRange);

    if (isAdminDashboard) {
        return useAdminGetIncomeStatementQuery({
            providerId,
            businessEntityId,
            ...serializeDateRange(dateRange),
        });
    }

    return useProviderGetIncomeStatementQuery({
        businessEntityId,
        ...serializeDateRange(dateRange),
    });
};

export default useGetIncomeStatementQuery;
