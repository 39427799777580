import React, { useState } from "react";
import PropTypes from "prop-types";
import PageTitle from "../../../../../PageTitle";
import Slider from "./Slider";
import Button from "../../../../../Button";
import { getCurrencyString } from "../../../../../../utils";
import STATES from "../states";
import Modal from "../../../../../Modal";
import styles from "./styles.module.css";

function SelectFundingAmountModal({
    onClose,
    availableBalanceCents,
    setRequestedFundingAmountCents,
    setState,
}) {
    const [fundingAmountCents, setFundingAmountCents] = useState(
        availableBalanceCents
    );

    const onButtonClick = () => {
        setRequestedFundingAmountCents(fundingAmountCents);
        setState(STATES.SCHEDULE_FUNDING_CALL);
    };

    return (
        <Modal onClose={onClose}>
            <div className={styles.container}>
                <PageTitle>Request funding</PageTitle>
                <div className={styles.subtitle}>
                    {`Drag the slider to select any funding amount up to ${getCurrencyString(
                        availableBalanceCents
                    )}. `}
                    Before we fund your bank account, we&apos;ll always have a
                    funding call with you to discuss the amount, interest rate,
                    and repayment terms of your offer.
                </div>
                <div className={styles.sliderContainer}>
                    <Slider
                        value={fundingAmountCents}
                        onChange={setFundingAmountCents}
                        max={availableBalanceCents}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Button onClick={onButtonClick}>
                        Schedule funding call
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

SelectFundingAmountModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    availableBalanceCents: PropTypes.number.isRequired,
    setRequestedFundingAmountCents: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
};

export default SelectFundingAmountModal;
