import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import InputField from "../../../InputField";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import Button from "../../../Button";
import SharedModal from "../../../Modal";
import PageTitle from "../../../PageTitle";
import useLazyDownloadReportAsyncQuery from "../../../../hooks/useLazyDownloadReportAsyncQuery";
import {
    selectDateRange,
    serializeDateRange,
} from "../../../../redux/slices/dateRange";
import getReadableReportType from "../../utils";
import useGetReportType from "../../../../hooks/useGetReportType";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import LabeledText from "../../../LabeledText";
import Label from "../../../Label";
import REPORT_TYPES from "../../constants";
import useGetFinancialReportsValidUpUntilQuery from "../../../../hooks/queriesAndMutations/useGetFinancialReportsValidUpUntilQuery";
import useIsAdminDashboard from "../../../../hooks/useIsAdminDashboard";
import DatePicker from "../../../DatePicker";
import Checkmark from "../../../../assets/Checkmark.svg";

function AsyncModal({ onClose }) {
    const reportType = useGetReportType();

    const dateRange = useSelector(selectDateRange);
    const [currentDateRange, setCurrentDateRange] = useState(dateRange);

    const handleDateRangeChange = (newDateRange) => {
        setCurrentDateRange(newDateRange);
    };

    const { businessEntityId } = useParams();
    const { data: businessEntities } = useGetBusinessEntitiesQuery();
    const businessEntity = businessEntities.find(
        (entity) => entity.id === businessEntityId
    );
    const businessEntityName =
        businessEntity.display_name || businessEntity.legal_name;

    const [trigger] = useLazyDownloadReportAsyncQuery();

    const { data: validUpUntilData } =
        useGetFinancialReportsValidUpUntilQuery();

    const [currentBalanceSheetEndDate, setCurrentBalanceSheetEndDate] =
        useState(dayjs(validUpUntilData.valid_up_until));

    const isAdminDashboard = useIsAdminDashboard();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
    });

    const [successMessage, setSuccessMessage] = useState(null);

    const handleFormSubmit = async (data) => {
        try {
            setSuccessMessage(null);

            const { email } = data;

            const { startDate } = currentDateRange;
            let { endDate } = currentDateRange;
            if (reportType === REPORT_TYPES.BALANCE_SHEET) {
                endDate = currentBalanceSheetEndDate;
            }

            await trigger({
                ...serializeDateRange({ startDate, endDate }),
                email,
            });

            setSuccessMessage("The report will be sent to your email shortly!");
        } catch (error) {
            console.error("Error downloading report:", error);
            setSuccessMessage(null);
        }
    };

    return (
        <SharedModal onClose={onClose}>
            <div className="flex flex-col">
                <div className="mb-6">
                    <PageTitle>{`Download ${getReadableReportType(
                        reportType
                    )}`}</PageTitle>
                </div>
                <div className="mb-6">
                    <LabeledText
                        label="Business Entity Name"
                        text={businessEntityName}
                    />
                </div>
                <div className="mb-6">
                    {reportType === REPORT_TYPES.BALANCE_SHEET ? (
                        <>
                            <div className="mb-2">
                                <Label>Please confirm the end date</Label>
                            </div>
                            <DatePicker
                                date={currentBalanceSheetEndDate}
                                onChange={setCurrentBalanceSheetEndDate}
                                maxDate={dayjs(validUpUntilData.valid_up_until)}
                            />
                        </>
                    ) : (
                        <>
                            <div className="mb-2">
                                <Label>Please confirm the date range</Label>
                            </div>
                            <CustomDateRangePicker
                                dateRange={currentDateRange}
                                onChange={handleDateRangeChange}
                                maxDate={
                                    isAdminDashboard
                                        ? dayjs()
                                        : dayjs(validUpUntilData.valid_up_until)
                                }
                            />
                        </>
                    )}
                </div>
                <div className="mb-8">
                    <div className="mb-2">
                        <Label>We&apos;ll send the report to this email</Label>
                    </div>
                    <div className="w-1/2">
                        <InputField
                            className="p-2 w-full rounded-full border-2 border-gray-300"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            validation={{
                                required: "Email is required",
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: "Invalid email address",
                                },
                            }}
                            register={register}
                            error={errors.email}
                            showError={false}
                        />
                    </div>
                </div>
                <div className="flex flex-row mr-8">
                    <div className="mr-4">
                        <Button
                            color="primary"
                            onClick={handleSubmit(handleFormSubmit)}
                            disabled={!isValid}
                        >
                            Download
                        </Button>
                    </div>
                    <Button color="secondary" onClick={onClose}>
                        Done
                    </Button>
                </div>
                {successMessage && (
                    <div className="flex items-center mt-4">
                        <img
                            className="w-[20px] mr-2"
                            src={Checkmark}
                            alt="Checkmark"
                        />
                        <p className="text-[14px] font-medium text-blue-purple">
                            {successMessage}
                        </p>
                    </div>
                )}
            </div>
        </SharedModal>
    );
}

AsyncModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default AsyncModal;
